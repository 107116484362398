import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/helpers";
import { RenderBeneficiaryProfile } from "./render-beneficiary-profile";
import _ from "lodash"
import { RenderBeneficiaryRequests } from "./render-beneficiary-requests";
import { RenderFamilyMembers } from "./render-family-members";
import { toast } from "react-toastify";
import { safe_get } from "../../report-visuals/report-utils";
import { error_toast } from "../../helpers/file-upload";
import { useTranslation } from "react-i18next";
import RNAccordion from "../../components/Accordion";
import {useParams} from "react-router";
import {TimelineLog} from "../grievance-management";
import {DeleteModal, SideOverlay} from "../../components";

export const BeneficiaryView = observer(props => {

    const rootStore = useStore();
    const { t } = useTranslation("beneficiary");
    const { beneficiaryStore, flexiStore } = rootStore;
    const { current_fsd } = flexiStore
    const { activeBeneficiary, beneficiary_delete, show_benefic } = beneficiaryStore;
    const { r1 } = activeBeneficiary;
    const {projectid} = useParams();
    const logs = safe_get(r1, "logs", []);

    useEffect(() => {
        if (!_.isEmpty(current_fsd)) {
            beneficiaryStore.set_active_beneficiary(current_fsd) //set current fsd as active beneficiary
        }
    }, [current_fsd]);

    const close_modal = async () => {
        beneficiaryStore.update_show_benefic(false);
        beneficiaryStore.update_edit_beneficiary_mode(false)
        await beneficiaryStore.resetActiveFamily()
        beneficiaryStore.resetActiveRequest()
    }

    if (_.isEmpty(activeBeneficiary)) return null;

    const onDeleteBeneficiary = async () => {
        const res = await flexiStore.delete_fsd(activeBeneficiary.id);
        if (res.status === 200) {
            toast.success("Beneficiary Deleted");
            beneficiaryStore.update_show_benefic(false);
            beneficiaryStore.update_edit_beneficiary_mode(false);

            const del_res = await flexiStore.fetch_fsdlist("beneficiary", parseInt(projectid));
            if (del_res.status === 200) {
                beneficiaryStore.set_beneficiries(del_res.data["results"]);
            } else {
                error_toast();
            }
        } else {
            error_toast();
        }
        beneficiaryStore.update_beneficiary_delete(false)
    }

    const onCancel = () => {
        beneficiaryStore.update_beneficiary_delete(false);
        beneficiaryStore.resetActiveFamily();
        beneficiaryStore.resetActiveRequest();
        beneficiaryStore.update_edit_beneficiary_mode(false);
    }

    return (
        <>
            <SideOverlay
                onClose={close_modal}
                show={show_benefic}
                title={t("beneficiary_info")}
            >
                <>
                    <RenderBeneficiaryProfile />
                    <RenderBeneficiaryRequests requests={safe_get(r1, "requests", [])} header={true} />
                    <RenderFamilyMembers family_members={safe_get(r1, "family", [])} header={true} />
                    {/*Render Logs*/}
                    <div className="rounded-lg bg-white">
                        <div className="flex flex-row items-center px-2">
                            <i className="far fa-stream text-xl pr-4 text-purple-500"/>
                            <h3 className="text-xl text-purple-500 font-bold py-2">{t("logs")}</h3>
                        </div>
                        <RNAccordion title={""} subtitle={"You can see the logs here"}>
                            <TimelineLog timeline_log={logs} mode={"VERTICAL"}/>
                        </RNAccordion>
                    </div>
                </>
            </SideOverlay>
            {beneficiary_delete && <DeleteModal
                onDeleteClick={onDeleteBeneficiary}
                onCancelClick={onCancel}
                isOpen={beneficiary_delete}
                id={activeBeneficiary.id}
                desc={t("delete_beneficiary")}
            />}
        </>
    )
})