import React from "react";
import { Route, Routes } from "react-router-dom";
import { ManageVotersdex } from "../features";
import { ElectionDayDashboard } from "../features";
import { FieldPayment } from "../features";
import { VoterReachOut } from "../features";
import { VoterReachOutIndex } from "../features";
import { SurveyDetails } from "../features";
import { TaskManager } from "../features";
import { VoterRegistrationTracker } from "../features";
import { DigitalRepository } from "../features/digital-repository";
import { BeneficiaryManagementV2 } from "../features/beneficiary-management-v2";
import { InfluentialLeadersHome } from "../features";
import { UserManagement } from "../features";
import { ManageProject, ProjectDashboardPage, QuestionPage } from "../pages";
import {
  BeneficiaryRoutes,
  C3Routes,
  GrievanceRoutes,
  ProjectWorksRoutes,
  PWV2Routes,
} from "./feature-routes";
import { GrievanceManagementV2 } from "../features/grievance-management-v2/grieviance-management-v2";
import { GrievanceV2Routes } from "./feature-routes/grievancev2-routes";

export const FeatureRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectDashboardPage />} />
      <Route path="/manage" element={<ManageProject />} />
      <Route path="/manage/survey/:surveyid/edit" element={<QuestionPage />} />
      <Route path="/fieldteamreport" element={<SurveyDetails />} />
      <Route path="/taskmanager" element={<TaskManager />} />
      <Route
        path="/voter_registration_tracker"
        element={<VoterRegistrationTracker />}
      />
      <Route path="/digital_repository" element={<DigitalRepository />} />
      <Route path="/beneficiary_v2" element={<BeneficiaryManagementV2 />} />
      <Route path="/influential_leaders" element={<InfluentialLeadersHome />} />
      <Route path="/usermanagement" element={<UserManagement />} />
      <Route path="/fieldpayment" element={<FieldPayment />} />
      <Route path="/manage-voters/" element={<ManageVotersdex />} />
      <Route
        path="/election_day_dashboard/"
        element={<ElectionDayDashboard />}
      />

      <Route path="/beneficiary_management/*" element={<BeneficiaryRoutes />} />
      <Route path="/grievance_management/*" element={<GrievanceRoutes />} />
      <Route
        path="/grievance_management_v2/*"
        element={<GrievanceV2Routes />}
      />
      <Route path="/project_works/*" element={<ProjectWorksRoutes />} />
      <Route path="/voter_reach_out" element={<VoterReachOutIndex />} />
      <Route path="/voter_reach_out/messages" element={<VoterReachOut />} />
      <Route path="/c3/*" element={<C3Routes />} />

      <Route path="/projectworks_v2" element={<PWV2Routes />} />
    </Routes>
  );
};
