import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  classNames,
  htmlValidation,
  SubHeading,
  useStore,
  error_toast,
} from "../../helpers";
import { useWindowSize } from "@withvoid/melting-pot";
import { useNavigate, useParams } from "react-router-dom";
import { AddEditGrievance } from "../grievance-management/add-edit-grievance";
import { toast } from "react-toastify";
import moment from "moment";
import { Chrono } from "react-chrono";
import { useTranslation } from "react-i18next";
import { safe_get } from "../../report-visuals/report-utils";
import { isEmpty } from "lodash";
import Avatar from "@mui/material/Avatar";
import {
  Carousel,
  DeleteButton,
  DeleteModal,
  EditButton,
  LoadingState,
  ProjectHeader,
  SideOverlay,
  ViewAttachment,
} from "../../components";
import { displayVillageNames } from "../project-works";
import { GrievanceCrumbs } from "./grievance-crumbs";
import { AddEditGrievanceV2 } from "./add-edit-grievance-v2";

import { useQuery } from "@tanstack/react-query";
export const TimelineLog = (props: any) => {
  const { timeline_log, mode } = props;

  const { t } = useTranslation("grievance_management");
  const [chrono, setChrono] = useState(false);

  useEffect(() => {
    setChrono(!chrono);
  }, [timeline_log]);

  return (
    <>
      <div
        className="task-view min-w-full"
        style={{ height: "80vh", zIndex: 0 }}
      >
        {/* this trick is to add items dynamically to overcome issue with chrono package */}
        {chrono && (
          <Chrono mode={mode}>
            {timeline_log
              ?.map((x, i) => {
                const image = safe_get(x, "who.profileImage", "");
                return (
                  <div key={i} className="flex flex-col w-full">
                    <div className="flex flex-row items-center">
                      <Avatar
                        alt={safe_get(x, "who.name", "K")}
                        src={image}
                        sx={{ width: 56, height: 56 }}
                      />
                      <div className="flex flex-col px-4">
                        <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                          {safe_get(x, "who.name", "")}
                        </p>
                        <p className="mt-1 flex items-center text-sm text-gray-600 mr-2 flex-nowrap">
                          {safe_get(x, "status", "")}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      {x["req_title"] && (
                        <p className="mt-1 flex font-bold  items-center text-sm text-gray-600 mr-2 flex-nowrap">
                          {safe_get(x, "req_title", "")}
                        </p>
                      )}
                      <p className="mt-1 flex items-center text-sm text-gray-600 mr-2 flex-nowrap">
                        {safe_get(x, "comments", "")}
                      </p>
                      <p className="mt-1 italic flex items-center text-sm text-gray-400 mr-2 flex-nowrap">
                        {moment(x["ts"], "DD/MM/YYYY h:mm:ss").format(
                          "MMMM Do YYYY, h:mm:ss A"
                        )}
                      </p>
                    </div>
                  </div>
                );
              })
              .reverse()}
          </Chrono>
        )}

        {/* this trick is to add items dynamically to overcome issue with chrono package */}

        {!chrono && (
          <Chrono mode={mode}>
            {timeline_log
              ?.map((x, i) => {
                return (
                  <div key={i} className="flex flex-row w-full">
                    <div className="flex-shrink-0">
                      <Avatar
                        alt={safe_get(x, "who.name", "K")}
                        src={safe_get(
                          x,
                          "who.profileImage",
                          "/images/user.png"
                        )}
                        sx={{ width: 56, height: 56 }}
                      />
                    </div>
                    <div className="flex flex-col pl-6">
                      <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
                        {safe_get(x, "who.name", "")}
                      </p>
                      <p className="mt-1 flex items-center text-smtext-gray-500 mr-2 flex-nowrap">
                        {safe_get(x, "status", "")}
                      </p>
                      <p className="mt-1 flex items-center text-sm text-gray-500 mr-2 flex-nowrap">
                        {safe_get(x, "comments", "")}
                      </p>
                      <p className="mt-1 flex items-center text-sm text-gray-400 italic mr-2 flex-nowrap">
                        {moment(x["ts"], "DD/MM/YYYY h:mm:ss").format(
                          "MMMM Do YYYY, h:mm:ss A"
                        )}
                      </p>
                    </div>
                  </div>
                );
              })
              .reverse()}
          </Chrono>
        )}
      </div>
    </>
  );
};

const GRDetails = ({ title, value, direction = "flex-row" }) => {
  return (
    <div
      className={classNames(
        direction === "flex-col" ? "flex-col" : " flex-row items-center",
        "flex"
      )}
    >
      <p className="text-md text-gray-800 font-bold pb-2">{title} :</p>
      <p className="text-gray-700 px-4 pb-2">{value}</p>
    </div>
  );
};

export const ViewGrievanceV2 = observer(() => {
  const rootStore = useStore();
  const params = useParams();
  const { grievanceStore, flexiStore } = rootStore;
  const { t } = useTranslation(["grievance_management", "project_work"]);

  const navigate = useNavigate();
  const { projectid } = useParams();
  const [showModal, setShowModal] = useState(false);

  const { data, isError, isLoading } = useQuery({
    queryKey: ["view-grievance", projectid],
    queryFn: async () => {
      const res = await flexiStore.get_fsdata(params.fsdid);
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Failed to fetch data");
    },
  });
  if (isError) {
    grievanceStore.update_grievance_store_property("isLoading", false);
    toast.error(t("try_again_later"));
  }
  if (isLoading) {
    grievanceStore.update_grievance_store_property("isLoading", true);
  } else {
    grievanceStore.update_grievance_store_property("isLoading", false);
  }
  // console.log(grievancedata);
  // const getViewGrievanceData = async () => {
  //   // @ts-ignore
  //   grievanceStore.update_grievance_store_property("isLoading", true);
  //   try {
  //     const res = await flexiStore.get_fsdata(params.fsdid);
  //     if (res.status === 200) {
  //       grievanceStore.update_grievance_store_property(
  //         "selectedGrievance",
  //         res.data
  //       );

  //       // setDefaultValues(defaultValuesGetter(res.data));
  //       // setData(res.data);
  //       console.log(res.data);
  //       flexiStore.set_current_fsd(res.data);
  //     } else toast.error(t("try_again_later"));
  //     grievanceStore.update_grievance_store_property("isLoading", false);
  //   } catch (e) {
  //     grievanceStore.update_grievance_store_property("isLoading", false);
  //     toast.error(t("try_again_later"));
  //   }
  // };
  useEffect(() => {
    // getViewGrievanceData();
    return () => {
      grievanceStore.update_grievance_store_property(
        "grievance_edit_mode",
        false
      );
      grievanceStore.update_grievance_store_property(
        "isShowGrievanceForm",
        false
      );
    };
  }, []);
  const voters = safe_get(data, "r1.voters", []);
  const { width } = useWindowSize();
  const isMobile = width <= 766;
  const handleCloseModal = () => {
    grievanceStore.update_grievance_store_property(
      "isShowGrievanceForm",
      false
    );
    grievanceStore.update_grievance_store_property("isViewGrievance", false);
  };

  const assigned_office_person = safe_get(
    data?.summary,
    "assigned_office_person",
    {}
  );

  const onDeleteGrievanceHnadler = async () => {
    const res = await flexiStore.delete_fsd(flexiStore.current_fsd.id);
    if (res.status === 200) {
      toast.success(t("grievance_deleted_successfully"));
      grievanceStore.update_grievance_store_property("isDeleteMode", false);
      navigate(-1);
    } else error_toast();
  };

  const grie_title = safe_get(data, "summary.title", "Grievance Details");

  return (
    <>
      {grievanceStore.isLoading && <LoadingState />}
      {!isMobile ? (
        <>
          <GrievanceCrumbs />
          <ProjectHeader />
        </>
      ) : null}

      <div className="px-4 viewgrievance">
        {/* <Header /> */}
        <div className="flex flex-row justify-between items-center my-2">
          <div className="py-2 flex flex-row justify-between items-center">
            <h2 className="text-3xl text-indigo-500 font-bold ">
              {grie_title}
            </h2>
          </div>
          <div className="flex flex-row mx-2 gap-x-2">
            <DeleteButton
              OnDeleteClickHandler={(e) => {
                htmlValidation(e);
                grievanceStore.update_grievance_store_property(
                  "isDeleteMode",
                  true
                );
              }}
            />

            {/* <button onClick={() => setShowModal(true)}>Edit V2</button> */}
            <EditButton OnEditClickHandler={() => setShowModal(true)} />
            {/* <EditButton
              OnEditClickHandler={() => {
                grievanceStore.update_grievance_store_property(
                  "grievance_edit_mode",
                  true
                );
                grievanceStore.update_grievance_store_property(
                  "isShowGrievanceForm",
                  true
                );
              }}
            /> */}
          </div>
        </div>
        <div className="flex flex-row w-full main">
          <div className="flex flex flex-col lg:w-2/3 grievancedetails w-full">
            {/* Grievance Details */}
            <div className="grid grid-cols-1 gap-y-4">
              <div className="items-start p-4 sm:px-4 bg-white rounded-lg shadow-lg">
                <div className="flex flex-row ite`ms-center ">
                  <i className="fas fa-ballot-check fp-text text-xl pr-2 sm:pr-4" />
                  <SubHeading title={t("grievance_details")} />
                </div>
                <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <GRDetails
                    title={t("title")}
                    value={data && data?.summary && data?.summary?.title}
                  />
                  <GRDetails
                    title={t("department")}
                    value={
                      data && data?.summary && data?.summary?.category_level_1
                    }
                  />
                  <GRDetails
                    title={t("complaint")}
                    value={
                      data && data?.summary && data?.summary?.category_level_2
                    }
                  />
                  <GRDetails
                    title={t("location")}
                    value={
                      data &&
                      data?.summary &&
                      displayVillageNames(data?.summary?.piv_level_2)
                    }
                  />
                  <GRDetails
                    title={t("priority")}
                    value={data && data?.summary && data?.summary?.priority}
                  />
                  <GRDetails
                    title={t("status")}
                    value={
                      data && data?.summary && data?.summary?.current_status
                    }
                  />
                  <GRDetails
                    title={t("comments")}
                    value={
                      data &&
                      data?.r1 &&
                      data?.r1?.timeline_log &&
                      data?.r1?.timeline_log[0]?.comments
                    }
                  />
                </div>
                <div className="px-4">
                  <GRDetails
                    title={t("description")}
                    direction="flex-col "
                    value={data && data?.summary && data?.summary?.description}
                  />
                </div>
              </div>
              {/*Department*/}
              <div className="items-start p-4 sm:px-4 bg-white rounded-lg shadow-lg">
                <section>
                  <div className="flex flex-row items-center ">
                    <i
                      className={
                        "fas fa-building text-xl pr-2 fp-text sm:pr-4 "
                      }
                    />
                    <SubHeading title={t("concerned_departement")} />
                  </div>

                  <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <GRDetails
                      title={t("concerned_departement")}
                      value={safe_get(data, "summary.dept", "N/A")}
                    />
                    {data &&
                      data?.summary &&
                      Object.keys(data?.summary?.assigned)?.map((x) => (
                        <GRDetails
                          title={t(`${x}`)}
                          value={data?.summary?.assigned[x]}
                        />
                      ))}
                  </div>
                </section>

                {!isEmpty(assigned_office_person) && (
                  <section>
                    <div className="flex flex-row items-center">
                      <i
                        className={
                          "fas fa-building text-xl pr-2 fp-text sm:pr-4 "
                        }
                      />
                      <SubHeading title={t("assigned_op")} />
                    </div>

                    <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <GRDetails
                        title={t("assigned_op")}
                        value={safe_get(assigned_office_person, "name", "N/A")}
                      />
                      {Object.keys(assigned_office_person)?.map((x) => (
                        <GRDetails
                          title={t(`${x}`)}
                          value={assigned_office_person[x]}
                        />
                      ))}
                    </div>
                  </section>
                )}

                <div>
                  {/* Attachments */}
                  {data && data?.r1 && data?.r1?.attachments?.length > 0 && (
                    <div className="flex flex-col my-2 items-start">
                      <div className="flex flex-row text-left w-full">
                        <SubHeading title="Uploaded Attachements" />
                      </div>
                      <div className="flex flex-row justify-between flex-wrap">
                        {/* <ViewAttachments data={data.r1.attachments} /> */}
                        {data?.r1?.attachments?.map((x, i) => {
                          return (
                            <div className="mx-2 px-2 underline">
                              <ViewAttachment
                                label={false}
                                key={i}
                                attachment={x}
                                backgroundColor={""}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* User Details */}
              <div className="flex flex-col items-start p-4 bg-white rounded-lg shadow-lg">
                <div className="flex flex-row items-center ">
                  <i className="fas fa-user-tie text-xl pr-2 sm:pr-4 fp-text" />
                  <SubHeading title={t("user_details")} />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-4">
                  <GRDetails
                    title={t("name")}
                    value={
                      data && data?.summary && data?.summary?.reported_by?.name
                    }
                  />
                  <GRDetails
                    title={t("phone")}
                    value={
                      data && data?.summary && data?.summary?.reported_by?.phone
                    }
                  />
                  <GRDetails
                    title={t("whatsapp_number")}
                    value={
                      data &&
                      data?.summary &&
                      data?.summary?.reported_by?.whatsapp
                    }
                  />
                  <GRDetails
                    title={t("voter_iD")}
                    value={
                      data &&
                      data?.summary &&
                      data?.summary?.reported_by?.voter_id
                    }
                  />
                  <GRDetails
                    title={t("aadhar_id")}
                    value={
                      data &&
                      data?.summary &&
                      data?.summary?.reported_by?.aadhar
                    }
                  />
                  <GRDetails
                    title={t("date_of_birth")}
                    value={
                      data && data?.summary && data?.summary?.reported_by?.dob
                    }
                  />
                  <GRDetails
                    title={t("age")}
                    value={
                      data && data?.summary && data?.summary?.reported_by?.age
                    }
                  />
                  <GRDetails
                    title={t("address")}
                    value={
                      data &&
                      data?.summary &&
                      data?.summary?.reported_by?.address
                    }
                  />
                  <GRDetails
                    title={t("caste")}
                    value={
                      data && data?.summary && data?.summary?.reported_by?.caste
                    }
                  />
                  <GRDetails
                    title={t("gender")}
                    value={
                      data &&
                      data?.summary &&
                      data?.summary?.reported_by?.gender
                    }
                  />
                </div>
                {/*<GRDetails title={t("description")} direction="flex-col" value={data && data.summary && data.summary?.reported_by?.description} />*/}
                {voters?.length > 0 && (
                  <div className="flex flex-col items-start rounded-lg ">
                    <SubHeading title={"Other Persons"} />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      {voters?.map((voter) => {
                        return (
                          <div className=" bg-gray-200 rounded-lg my-2 p-2">
                            <p className="font-bold text-md">
                              {voter["profile"]["name_eng"]}
                            </p>
                            <p>{voter["phone"]}</p>
                            <p>{voter["profile"]["address"]}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {/* Media */}
              {data && data?.r1 && data?.r1?.richmedia?.length > 0 && (
                <div className="p-4 sm:px-6 cursor-pointer bg-white hover:bg-gray-50 rounded-lg shadow-lg items-start">
                  <div className="flex flex-row text-left w-full">
                    <SubHeading title={t("uploaded_media")} />
                  </div>
                  <div className="flex flex-col">
                    {
                      <Carousel
                        items={data?.r1?.richmedia?.map((x, i) => {
                          return (
                            <div style={{ width: "500px" }}>
                              <ViewAttachment
                                label={false}
                                key={i}
                                attachment={x}
                                backgroundColor={""}
                              />
                            </div>
                          );
                        })}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*TimeLine - Start*/}
          <div className="flex flex flex-col lg:w-1/3 h-full timelinelog">
            {data && data?.r1 && data?.r1?.timeline_log && (
              <>
                <div className="flex flex-row items-center px-8 pb-4">
                  <i className="fas fa-clock text-xl pr-4 fp-text" />
                  <SubHeading
                    title={t("timeline_log", { ns: "project_work" })}
                  />
                </div>
                <TimelineLog
                  timeline_log={data?.r1?.timeline_log}
                  mode={"VERTICAL"}
                />
              </>
            )}
          </div>
          {/*TimeLine - End*/}
        </div>
      </div>

      <SideOverlay
        children={<AddEditGrievance getGrievanceList={data} />}
        onClose={handleCloseModal}
        show={grievanceStore?.isShowGrievanceForm}
        title={t("edit_grievance")}
      />
      <SideOverlay
        children={
          <AddEditGrievanceV2
            // projectid={projectID}
            isEditMode={true}
            closeModal={() => setShowModal(false)}
            dataToEdit={data}
          />
        }
        onClose={() => setShowModal(false)}
        show={showModal}
      />
      {grievanceStore?.isDeleteMode && (
        <DeleteModal
          onDeleteClick={onDeleteGrievanceHnadler}
          onCancelClick={() => {
            grievanceStore?.update_grievance_store_property(
              "isDeleteMode",
              false
            );
          }}
          isOpen={grievanceStore?.isDeleteMode}
          desc={t("you_want_to_delete")}
        />
      )}
    </>
  );
});
