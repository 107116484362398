import {useQuery} from "@tanstack/react-query";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../helpers";
import {safe_get} from "../../../report-visuals";
import {useGrievanceUtilFunctions} from "./useGrievanceUtilFunctions";
import {useForm, useWatch} from "react-hook-form";

export const useGrievanceOptions = () => {
  //   useQuery();
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const grievanceProperties = projectStore.getProjectProperty("grievances", {});
  const projectProperties = projectStore.getProjectProperty("grievances", {});
  const profile_questions = projectStore.getProjectProperty(
    "profile_questions",
    {}
  );
  const genders = ["Male", "Female", "Third Gender"];
  const [selectedDept, setSelectedDept] = useState();
  const [complaintOptions, setComplaintOptions] = useState([]);
  const [selectedHobli, setSelectedHobli] = useState();
  const [villageOptions, setVillageOptions] = useState([]);
  const [authorityPerson, setAuthorityPerson] = useState([]);
  const [concernedDept, setConcernedDept] = useState<string | undefined>();
  const [defaultValues, setDefaultValues] = useState({});
  const [userValues, setUserValues] = useState();
  const {
    getAuthorityPersonOptions,
    getOptions,
    getStatusOptions,
    getVillageOptions,
    hierarchy_option,
    getConcernedOfficePersonsOptions,
    defaultValuesGetter,
  } = useGrievanceUtilFunctions();
  useEffect(() => {
    if (userValues) {
      const values = defaultValuesGetter(userValues);
      // console.log(values);
      setDefaultValues(values);
    }
  }, [userValues]);
  useEffect(() => {
    console.log(defaultValues);
  }, [defaultValues]);
  useEffect(() => {
    if (concernedDept) {
      setAuthorityPerson(
        getAuthorityPersonOptions(
          safe_get(
            grievanceProperties,
            `departments.${concernedDept}.owners`,
            []
          )
        )
      );
    }
  }, [concernedDept]);
  useEffect(() => {
    if (selectedDept) {
      // console.log(selectedDept);
      setComplaintOptions(
        getOptions(
          safe_get(grievanceProperties, `categories.${selectedDept}`, [])
        )
      );
    }
  }, [selectedDept]);
  useEffect(() => {
    if (selectedHobli) {
      const selectedVillage = village_options.find(
        (value) => value.id === selectedHobli
      );
      console.log(selectedVillage);
      setVillageOptions(selectedVillage.children);
    }
  }, [selectedHobli]);

  const onEngSave = (eng) => {
    console.log(eng);
    setAuthorityPerson([
      ...authorityPerson,
      { label: eng.name, value: eng.name },
    ]);
  };
  const departmentOptions = getOptions(
    Object.keys(safe_get(projectProperties, "categories", {}))
  );
  const hobli_options = hierarchy_option(
    projectStore.getProjectProperty("hierarchy", [])
  );
  const village_options = getVillageOptions(
    projectStore.getProjectProperty("hierarchy", [])
  );
  const statusOptions = getStatusOptions(
    safe_get(projectProperties, "status_config", [])
  );
  const priorityOptions = getOptions(
    safe_get(projectProperties, "priority", [])
  );
  const beneficiary = projectStore.getProjectProperty("beneficiary", {});
  const concerned_office_persons_options = getConcernedOfficePersonsOptions(
    safe_get(beneficiary, "owners", [])
  );
  const casteOptions = safe_get(profile_questions, "caste", []);
  const genderOptions = getOptions(genders);
  return {
    authorityPerson,
    departmentOptions,
    complaintOptions,
    setSelectedDept,
    setSelectedHobli,
    setConcernedDept,
    onEngSave,
    setDefaultValues,
    defaultValues,
    hobli_options,
    villageOptions,
    statusOptions,
    priorityOptions,
    genderOptions,
    casteOptions,
    concerned_office_persons_options,
    beneficiary,
  };
};
