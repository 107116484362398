import { useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { useStore } from "../../../helpers";
import { useParams } from "react-router";
type beneficiaryUpdateParams = {
  summaryPayload: any;
  r1Payload: any;
};
export const useUpdateGrievance = () => {
  const rootStore = useStore();
  const { flexiStore } = rootStore;
  const queryClient = useQueryClient();
  const { projectid } = useParams();

  const onGrievanceUpdate = async (summaryPayload, r1Payload) => {
    await flexiStore.update_current_fsd("summary", summaryPayload);
    await flexiStore.update_current_fsd("r1", r1Payload);
    await flexiStore.update_current_fsd("projectid", parseInt(projectid));
    const response = await flexiStore.api_update_current_fsd();
    if (response.status !== 200) {
      throw new Error("Something went wrong");
    }
    return response;
  };

  return useMutation({
    mutationFn: async ({
      summaryPayload,
      r1Payload,
    }: beneficiaryUpdateParams) => onGrievanceUpdate(summaryPayload, r1Payload),
    onSuccess: () => {
      setTimeout(async () => {
        await queryClient.invalidateQueries(["view-grievance", projectid]);
      }, 1000);
      queryClient.invalidateQueries(["view-grievance"]);
      toast.success("grievance updated sucessfully");
    },
    onError: () => {
      toast.error("Couldn't update ,something went wrong");
    },
  });
};
