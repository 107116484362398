import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react'
import {instanceToPlain} from "class-transformer";
import {BriefcaseIcon, UserCircleIcon} from "@heroicons/react/24/solid";
import {TabView} from "../../../components";
import {useWindowSize} from '@withvoid/melting-pot'
import {observer} from "mobx-react-lite";
import {useCurrentFSD} from "../../../custom-hooks/useCurrentFSD";
import {safe_get} from "../../../report-visuals/report-utils";
import _ from "lodash";
import {makeid_numeric} from "../../../helpers";
import {useTranslation} from 'react-i18next';

const tabs = [
    {name: 'Raw Data', icon: UserCircleIcon},
    {name: 'Reports', icon: BriefcaseIcon}
]

export const BoothDataModal = observer((props: any) => {

    const {open, onCookedBoothDataUpdate, handleCancel, data} = props;
    const config = useCurrentFSD();
    const {booth_details, booth_key_prefix, qlty_sections} = config;
    const booth_list = Object.keys(booth_details).sort()
    const default_booth = booth_list[0];
    const [activeTab, setActiveTab] = useState("Raw Data");
    const [t] = useTranslation(["booth_bank", "beneficiary","settings"]);

    const {height} = useWindowSize();

    const get_booth_json_string = (booth_key) => {
        try {
            const booth_data = data[`${booth_key_prefix}${booth_key}`]
            if (!booth_data) return '{}';
            return JSON.stringify(instanceToPlain(booth_data), null, 4)
        } catch (e) {
            return '{}'
        }
    }

    const [current_active_booth, set_current_active_booth] = useState(default_booth)
    const [jsonTxtData, setJsonTxtData] = useState(get_booth_json_string(default_booth))
    const [useQltySections, setUseQltySections] = useState({});

    useEffect(() => {
        const json_txt = get_booth_json_string(current_active_booth)
        setJsonTxtData(json_txt);
        setUseQltySections(safe_get(data, `${booth_key_prefix}${current_active_booth}`, {}))
    }, [current_active_booth])

    const onTabChange = (tab) => {
        setActiveTab(tab)
    }

    const on_editor_change = (val, key) => {
        const clone = _.clone(useQltySections);

        if (!Object.keys(clone).includes(key)) {
            clone[`${key}`] = {
                "value": val,
                "id": makeid_numeric(6)
            }
        } else {
            clone[key]["value"] = val;
        }
        setUseQltySections(clone)
    }

    const reset_tab = () => {
        setActiveTab("Raw Data");
        set_current_active_booth(default_booth)
    }

    const onTableDataChange = (data, key) => {
        console.log("data", data)
        const clone = _.clone(useQltySections);

        clone[`${key}`] = {
            "value": data,
            "id": makeid_numeric(6),
            "type": "table"
        }

        setUseQltySections(clone)
    }

    return (
        <Transition.Root
            show={open}
            as={Fragment}>
            <Dialog as="div" className="relative z-10" style={{zIndex: 9999}} onClose={() => {
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="self-center w-full bg-gray-100 rounded-lg overflow-hidden shadow-xl sm:mx-8 transform transition-all sm:my-2 sm:p-3"
                                style={{minHeight: height * 0.95, height: "auto"}}>
                                <div className="flex flex-col flex-wrap sm:flex-nowrap">
                                    <div
                                        className="inline-block align-bottom transform transition-all sm:my-2 sm:align-middle sm:w-full sm:p-3">
                                        <div className="pb-2 font-bold text-left text-xl">{t("edit_booth_report")}</div>
                                        <div className="flex flex-row items-center">
                                            <div className="my-4 mr-2">{t("select_booth")}</div>
                                            <select className="border border-2 rounded-lg px-2 h-10 border-gray-600"
                                                    onChange={e => {
                                                        set_current_active_booth(e.target.value)
                                                    }}>
                                                {booth_list.map(x => {
                                                    return (
                                                        <option value={x}>{booth_key_prefix}{x}</option>
                                                    )
                                                })}
                                            </select>
                                            <div className='ml-auto'>
                                                <div className="flex flex-row-reverse justify-end items-center relative"
                                                     style={{top: 0, left: 0}}>
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-500 sm:text-sm mx-2"
                                                        onClick={() => {
                                                            handleCancel();
                                                            reset_tab();
                                                        }}
                                                    >
                                                        {t("cancel", {ns:"beneficiary"})}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:text-sm mx-2"
                                                        onClick={async () => {
                                                            try {
                                                                if (activeTab === "Raw Data") {
                                                                    data[`${booth_key_prefix}${current_active_booth}`] = JSON.parse(jsonTxtData)
                                                                }
                                                                if (activeTab === "Reports") {
                                                                    data[`${booth_key_prefix}${current_active_booth}`] = useQltySections
                                                                }
                                                                await onCookedBoothDataUpdate(data, booth_key_prefix, current_active_booth)
                                                                reset_tab();
                                                            } catch (e) {
                                                                alert('Invalid JSON. Not saving the changes')
                                                            }
                                                        }}
                                                    >
                                                        {t("update", {ns:"settings"})}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <TabView tabs={tabs} onChange={onTabChange}/>

                                        {activeTab === "Raw Data" &&
                                        <textarea className='text-code w-full bg-gray-50 px-4 py-2 min-h-full'
                                                  value={jsonTxtData}
                                                  onChange={(evt) => {
                                                      setJsonTxtData(evt.target.value)
                                                  }}
                                                  style={{height: height * 0.75}}
                                        />
                                        }
                                        {activeTab === "Reports" ? <>
                                            {qlty_sections.map((section, index) => {
                                                const slate = safe_get(useQltySections, `${section.key}.value`, {});
                                                const render_key = `${current_active_booth}_${section.key}`;
                                                const x = safe_get(useQltySections, `${section.key}.id`, render_key);
                                                if (section.type === "table") return null;
                                                console.log(section.type)
                                                return (
                                                    <div key={x} className="text-left my-2">
                                                        <div className="font-bold text-xl py-1">{section.display}</div>
                                                        {/*<SlateEditor */}
                                                        {/*    data={slate} read_only={false}*/}
                                                        {/*            */}
                                                        {/*             onEditorChange={on_editor_change}*/}
                                                        {/*             more={{key: section.key}}*/}
                                                        {/*/>*/}
                                                    </div>
                                                )
                                            })}
                                        </> : null}
                                    </div>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
})
