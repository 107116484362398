import * as yup from "yup";

export const addEditGrievanceFormSchema = yup.object().shape({
  complaint_title: yup
    .string()
    .min(3, "Min 3 characters")
    .required("Complaint Title is required"),
  department: yup.string().required("Deparment is required"),
  complaint_type: yup.string().required("Complaint Type is required"),
  specific_complaint: yup.string(),
  hobli: yup.string().required("Hobli is required"),
  village: yup.string().required("Village is required"),
  more_details: yup
    .string()
    .min(10, "Minimum 10 characters required")
    .required("More details required"),
  status: yup.string().required("Status is required"),
  priority: yup.string().required("Status is required"),
  concerned_person: yup.string().required("Concerned person is required"),
  concerned_department: yup
    .string()
    .required("Concerned department is required"),
  authority_person: yup.string(),
  person_name: yup
    .string()
    .min(3, "Min 3 characters")
    .matches(/^[A-Za-z\s]+$/, "Person Name must contain letters only")
    .required("Person Name is required"),
  phone_number: yup
    .string()
    .min(10, "Phone number should be 10 digits")
    .max(10)
    .matches(/^[0-9]+$/, "Phone number must be numeric")
    .required("Phone number is required"),
  whatsapp_number: yup
    .string()
    .min(10, "Phone number should be 10 digits")
    .max(10)
    .matches(/^[0-9]+$/, "Phone number must be numeric")
    .required("Phone number is required"),
  voter_id: yup
    .string()
    .max(10)
    .min(10)
    .matches(/^[a-zA-Z0-9]+$/, "Voter ID is a 10 digit alphanumeric value"),
  aadhar_id: yup
    .string()
    .min(12, "Aadhar number is of 12 digit")
    .max(12, "Aadhar number is of 12 digit")
    .matches(/^[0-9]+$/, "Aadhar number is numeric")
    .required("Aadhar number is required"),
  dob: yup.string().required("Date of Birth is required"),
  age: yup
    .number()
    .max(100, "How are you even alive !")
    .min(18, "You should be atleast 18 years of age")
    .required("Age is required please input your date above"),
  address: yup
    .string()
    .min(10, "Minimum 10 characters required")
    .required("Address is required"),
  caste: yup.string().required("Caste is required"),
  gender: yup.string().required("Gender is required"),
  attachments: yup
    .mixed()
    .test("required", "Attachments are required", (value) => {
      return value && value instanceof FileList && value.length > 0;
    })
    .required("attachments are needed !"),
  media: yup
    .mixed()
    .test("required", "Media is required", (value) => {
      return value && value instanceof FileList && value.length > 0;
    })
    .required("media is needed !"),
});
