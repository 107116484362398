import React, { useEffect, useState } from "react";
import {
  SideOverlay,
  LoadingState,
  ProjectHeader,
  FilterButton,
} from "../../components";
import { useStore } from "../../helpers";
import { Button } from "@mui/material";
import { useIsMobile } from "../../custom-hooks";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import {AddEditGrievanceV2}  from "./add-edit-grievance-v2";
import { GrievanceCrumbs } from "./grievance-crumbs";
import { RNSearchBar } from "../influential-leaders";
import { GrievanceFilter } from "../grievance-management/grievanceFilter";
import { observer } from "mobx-react-lite";
import { DataNotAvailable } from "../../flexistore/renderkeys/mh-2024-field";
import GrievanceTableV3 from "./grievance-table-v3";

export const GrievanceManagementV2 = observer((props: any) => {
  const [showModal, setShowModal] = useState(false);
  const { projectid } = useParams();
  const [query, setQuery] = useState("");
  const rootStore = useStore();
  const { grievanceStore, flexiStore } = rootStore;
  const { isShowGrievanceForm, FilterStatus, filters } = grievanceStore;
  const isMobile = useIsMobile();

  const getGrievanceList = async () => {
    await flexiStore.set_fsd_search_query(query);
    const res = await flexiStore.fetch_fsdlist(
      "grievance",
      parseInt(projectid),
      filters
    );
    if (!res.ok) {
      throw new Error("Something went wrong");
    }
    return res;
  };

  const { data, isError, isLoading } = useQuery({
    queryKey: ["grievance", query, projectid],
    queryFn: getGrievanceList,
    refetchOnMount: true,
  });

  const handleSearchBarChange = (value) => {
    setQuery(value);
  };

  const closeFilterModal = () => {
    grievanceStore.setFilterStatus(false);
  };

  if (isLoading) return <LoadingState />;
  return (
    <div>
      {!isMobile ? (
        <>
          <GrievanceCrumbs />
          <ProjectHeader />
        </>
      ) : null}
      <div className="mt-2 flex flex-col justify-center items-center border-slate-300 rounded-md border-1 mx-auto p-4 max-w-max shadow-md">
        <div className="flex flex-row flex-wrap justify-between">
          <div>
            <h2 className="font-bold text-3xl text-indigo-500 pr-14 text-center">
              Grievance Management
            </h2>
          </div>
          <div className="mr-16">
            <RNSearchBar onSearch={handleSearchBarChange} />
          </div>
          <div className="ml-4 mr-4">
            <Button
              variant={"contained"}
              startIcon={<i className="fas fa-plus-circle" />}
              onClick={() => setShowModal(true)}
            >
              {"new_grievance"}
            </Button>
          </div>
          <div className="ml-4">
            <FilterButton
              filterOnClickHandler={() => grievanceStore.setFilterStatus(true)}
            />
          </div>
        </div>
      </div>
      <div className="m-6">
        {data?.data?.results.length > 0 ? (
          // <GrievanceTableV2 data={data?.data?.results} />
          <GrievanceTableV3 data={data?.data?.results} />
        ) : (
          <DataNotAvailable />
        )}
      </div>

      {/* <Button
        variant={"contained"}
        startIcon={<i className="fas fa-plus-circle" />}
        onClick={() => setShowModal(true)}
      >
        {"new_grievance"}
      </Button> */}
      <SideOverlay
        children={
          <AddEditGrievanceV2
            // projectid={projectID}
            isEditMode={false}
            closeModal={() => setShowModal(false)}
          />
        }
        onClose={() => setShowModal(false)}
        show={showModal}
      />
      <SideOverlay
        children={<GrievanceFilter />}
        onClose={closeFilterModal}
        show={FilterStatus}
        title={"Grievance Filters"}
      />
    </div>
  );
});

// ----------------------------------
// const rootStore = useStore();
//   const { grievanceStore, flexiStore } = rootStore;
//   const { fsd_search_page_size } = flexiStore;
//   const [pageNumber, setPageNumber] = useState(1);
//   const [total, setTotal] = useState(0);
//   const [query, setQuery] = useState("");
//   const { isLoading, isShowGrievanceForm, FilterStatus, filters } =
//     grievanceStore;
//   useEffect(() => {
//     //TODO: Reset filters when this component unmounts
//     return () => {
//       grievanceStore.resetFilters();
//     };
//   }, []);
//   const { projectid } = useParams();
//   const getGrievanceList = async () => {
//     await flexiStore.set_fsd_search_query(query);
//     const res = await flexiStore.fetch_fsdlist(
//       "grievance",
//       parseInt(projectid),
//       filters
//     );
//     if (!res.ok) {
//       throw new Error("Something went wrong");
//     } else return res;
//   };
//   const handleCloseModal = () => {
//     grievanceStore.update_grievance_store_property(
//       "isShowGrievanceForm",
//       false
//     );
//     grievanceStore.update_grievance_store_property("isViewGrievance", false);
//   };
//   const addEditGrievance = async () => {
//     grievanceStore.update_grievance_store_property(
//       "grievance_edit_mode",
//       false
//     );
//     grievanceStore.update_grievance_store_property("isLoading", true);
//     const res = await flexiStore.clone_fsd(420);
//     grievanceStore.update_grievance_store_property("isLoading", false);
//     if (res.status === 200) {
//       grievanceStore.update_grievance_store_property(
//         "clonedGrievance",
//         res.data
//       );
//       flexiStore.set_current_fsd(res.data);
//       grievanceStore.update_grievance_store_property(
//         "isShowGrievanceForm",
//         true
//       );
//     }
//   };
//   const { isLoading: loading } = useQuery({
//     queryKey: [
//       "grievances",
//       projectid,
//       filters,
//       query,
//       pageNumber,
//       fsd_search_page_size,
//     ],
//     queryFn: getGrievanceList,
//     onSuccess: (data) => {
//       grievanceStore.update_grievance_store_property(
//         "grievanceList",
//         data["data"]["results"]
//       );
//       setTotal(data["data"]["total"]);
//     },
//     onError: (e) => console.log(e["message"]),
//   });
// -------------------------------------------
// {(isLoading || loading) && <LoadingState />}
