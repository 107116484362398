import React, { useState } from "react";
import { toast } from "react-toastify";
import { safe_get } from "../../../report-visuals";
import { makeid_alpa_numeric, useFileUpload, useStore } from "../../../helpers";
import { useParams } from "react-router";
import moment from "moment";
import _ from "lodash";

export const useGrievanceUtilFunctions = () => {
  const rootStore = useStore();
  const {
    projectStore,
    flexiStore,
    userStore,
    grievanceStore,
    beneficiaryStore,
  } = rootStore;

  const [attachments, setAttachments] = useState([]);
  const [richMedia, setRichMedia] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const { uploadFileToS3, isUploading } = useFileUpload();
  const { projectid } = useParams();
  const ATTACHMENT_PATH = `project-${projectid}/grievance/${flexiStore?.current_fsd?.id}/docs`;
  const MEDIA_PATH = `project-${projectid}/grievance/${flexiStore?.current_fsd?.id}/media`;
  const grievanceProperties = projectStore.getProjectProperty("grievances", {});
  const getOptions = (value) => {
    return value.map((ele) => {
      return { label: ele, value: ele };
    });
  };

  const getStatusOptions = (value) => {
    return value.map((ele) => {
      return { label: ele.status, value: ele.status };
    });
  };
  const hierarchy_option = (hierarchy) => {
    return hierarchy.map((x) => {
      return {
        label: x.label,
        value: x.value,
      };
    });
  };
  const getVillageOptions = (value) => {
    return value.map((ele) => {
      return ele;
    });
  };
  const getConcernedOfficePersonsOptions = (value) => {
    return value.map((ele) => {
      return {
        label: ele.name + ", " + ele.designation,
        value: ele.name,
      };
    });
  };
  const getAuthorityPersonOptions = (value) => {
    return value.map((ele) => {
      return { label: ele.name, value: ele.name };
    });
  };
  const addNewComplaint = async (newComplaint, department) => {
    const activeProjectProperties = JSON.parse(
      localStorage.getItem("__active_project")
    );
    const id = safe_get(activeProjectProperties, "id", 1);
    const payload = {
      id: id,
      properties: {
        ...activeProjectProperties.properties,
        grievances: {
          type: "json",
          value: {
            ...activeProjectProperties.properties.grievances.value,
            categories: {
              ...activeProjectProperties.properties.grievances.value.categories,
              [department]: [
                ...activeProjectProperties.properties.grievances.value
                  .categories[department],
                newComplaint,
              ],
            },
          },
        },
      },
    };
    console.log(payload);
    const result = await projectStore.editProjectProperties(payload);
    if (result.status === 200) {
      toast.success("new_compliant_suc");
    } else {
      toast.error("something_went_wrong");
    }
  };
  const attachmentUploadHandler = async (attachments, type) => {
    if (!attachments || attachments.length === 0) {
      console.error("No attachments found");
      return;
    }
    Array.from(attachments).forEach((fileToUpload, index) => {
      setTimeout(async () => {
        const uploadedData = await uploadFileToS3(
          type === "attachment" ? ATTACHMENT_PATH : MEDIA_PATH,
          fileToUpload,
          "urn-assets"
        );

        if (uploadedData?.status === 204) {
          type === "attachment"
            ? setAttachments((prevFiles) => [
                ...prevFiles,
                {
                  path: uploadedData.key,
                  type: fileToUpload["type"],
                  name: fileToUpload["name"],
                },
              ])
            : setRichMedia((prevFiles) => [
                ...prevFiles,
                {
                  path: uploadedData.key,
                  type: fileToUpload["type"],
                  name: fileToUpload["name"],
                },
              ]);
          setUploadedFile((prevFiles) => [
            ...prevFiles,
            {
              path: uploadedData.key,
              type: fileToUpload["type"],
              name: fileToUpload["name"],
            },
          ]);

          toast.success("Attachments uploaded");
        } else {
          toast.error("Please try again later");
        }
      }, 1000 * index);
    });
  };
  const addPayloadGetter = async (
    data,
    beneficiary,
    villageOptions,
    defaultAttachments = [],
    defaultRichMedia = []
  ) => {
    if (data?.specific_complaint) {
      await addNewComplaint(data?.specific_complaint, data?.department);
    }
    const concerned_person_raw = safe_get(beneficiary, "owners", []);
    console.log(concerned_person_raw);
    const assigned_office_person = concerned_person_raw.find(
      (person) => person.name === data.concerned_person
    );

    const authority_person_raw = safe_get(
      grievanceProperties,
      `departments.${data.concerned_department}.owners`,
      []
    );
    const assigned_authority_person = authority_person_raw.find(
      (person) => person.name === data?.authority_person
    );
    const village_details = villageOptions.find(
      (village) => village.id === data?.village
    );
    const summary_payload = {
      assigned: { ...assigned_authority_person },
      assigned_office_person: { ...assigned_office_person },
      category_level_1: data?.department,
      category_level_2: data?.complaint_type,
      caste: data?.caste,
      current_status: data?.status,
      dept: data?.concerned_department,
      description: data?.more_details,
      location: data?.hobli,
      piv_level_1: data?.hobli,
      piv_level_2: [{ ...village_details?.children[0] }],
      priority: data?.priority,
      reported_by: {
        aadhar: data?.aadhar_id,
        address: data?.address,
        age: data?.age,
        caste: data?.caste,
        dob: data?.dob,
        gender: data?.gender,
        name: data?.person_name,
        phone: data?.phone_number,
        voter_id: data?.voter_id,
        whatsapp: data?.whatsapp_number,
      },
      sla: "1w 1h 2d",
      title: data?.complaint_title,
    };
    let r1Payload = {
      timeline_log: [
        {
          id: makeid_alpa_numeric(3),
          status: data?.status,
          comments: data?.more_details,
          ts: moment().format("DD/MM/YYYY HH:mm:ss"),
          who: {
            //current user info
            name: userStore.user.profile.name,
            phone: userStore.user.phonenum,
            address: userStore.user.profile.address,
            comments: data?.more_details,
            profileImage: userStore.user.profileimageuri,
          },
        },
      ],
      richmedia: [...richMedia, ...defaultRichMedia],
      attachments: [...attachments, ...defaultAttachments],
      voters: safe_get(grievanceStore, "selected_voters", []),
    };
    const payload = {
      key: "grievance",
      renderkey: "grievance",
      properties: {
        projectid: parseInt(projectid),
        summary: {
          ...summary_payload,
          tracing_id: `P${projectid}G`,
        },
        r1: {
          ...r1Payload,
        },
      },
    };

    if (!isUploading && richMedia.length > 0 && attachments.length > 0) {
      return payload;
    } else {
      return;
    }
  };
  const defaultValuesGetter = (data) => {
    const formValues = {
      complaint_title: data?.summary?.title,
      department: data?.summary?.category_level_1,
      complaint_type: data?.summary?.category_level_2,
      specific_complaint: "",
      hobli: data?.summary?.location,
      village:
        data?.summary?.piv_level_2?.length > 0
          ? data?.summary?.piv_level_2[0]?.piv_level1
          : data?.summary?.piv_level1,
      more_details: data?.summary?.description,
      status: data?.summary?.current_status,
      priority: data?.summary?.priority,
      concerned_person: data?.summary?.assigned_office_person?.name,
      concerned_department: data?.summary?.dept,
      authority_person: data?.summary?.assigned?.name,
      person_name: data?.summary?.reported_by?.name,
      phone_number: data?.summary?.reported_by?.phone,
      whatsapp_number: data?.summary?.reported_by?.whatsapp,
      voter_id: data?.summary?.reported_by?.voter_id,
      aadhar_id: data?.summary?.reported_by?.aadhar,
      dob: data?.summary?.reported_by?.dob,
      age: data?.summary?.reported_by?.age,
      address: data?.summary?.reported_by?.address,
      caste: data?.summary?.reported_by?.caste,
      gender: data?.summary?.reported_by?.gender,
      attachments: data?.r1?.attachments,
      media: data?.r1?.richmedia,
    };
    return formValues;
  };
  const editPayloadGetter = (formData, dataToEdit) => {
    const toUpdateData = _.cloneDeep(dataToEdit);
    console.log(toUpdateData);
    const merged_object = _.merge({}, toUpdateData, formData);
    console.log(formData);
    console.log(merged_object);
    return {
      summaryPayload: merged_object.summary,
      r1Payload: merged_object.r1,
    };
  };
  return {
    addNewComplaint,
    attachmentUploadHandler,
    defaultValuesGetter,
    getAuthorityPersonOptions,
    getOptions,
    getStatusOptions,
    hierarchy_option,
    getVillageOptions,
    getConcernedOfficePersonsOptions,
    addPayloadGetter,
    editPayloadGetter,
  };
};
