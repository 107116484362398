import React, {useState, useEffect, useRef} from "react";
import {
    useStore,
    file_icon,
    htmlValidation,
    makeid_alpa_numeric,
    remove_item_from_array,
    useFileUpload, getObjByName,
} from "../../../helpers";
import {yupResolver} from "@hookform/resolvers/yup";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {safe_get} from "../../../report-visuals";
import {FormButtons, TailwindModal, UploadButton} from "../../../components";
import {IconButton} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {RNInput, RNSelect, FileInputField,} from "../../../components/form-components";
import {Stack} from "@mui/material";
import {VortexSpinner} from "../../digital-repository";
import {toast} from "react-toastify";
import moment from "moment";
import {beneficiaryRequestDefaultValues, beneficiaryRequestType} from "./request-types";
import {beneficiaryRequestValidations} from "./br-validation-schema";
import {RequestAssigned} from "./request-assigned";
import _ from "lodash"

export const AddBeneficiaryRequests = observer((props: any) => {

    const {concernedPersons} = props;
    const params = useParams();
    const rootStore = useStore();
    const projectid = params.projectid;

    const {t} = useTranslation(["beneficiary", "grievance_management"]);
    const {beneficiaryStore, projectStore, beneficiaryStoreV2} = rootStore;
    const {add_request_modal, activeRequest} = beneficiaryStore;
    const {isUploading, uploadFileToS3} = useFileUpload();

    const beneficiary_property = projectStore.getProjectProperty("beneficiary");
    const categories = safe_get(beneficiary_property, "categories", {});
    const status_config = safe_get(beneficiary_property, "status_config", []);

    const [schems, setSchems] = useState([]);
    const [uploadedFile, setUploadedFile] = useState([]);

    const departments = Object.keys(categories).map((x) => getObjByName(x));
    const path = `project-${projectid}/beneficiary/request`;
    const beneficRequestStatus = status_config.map((x) => getObjByName(x["status"]));

    const addNewRequestHandler = () => {
        beneficiaryStore.update_add_request_modal(true);
        beneficiaryStore.resetActiveRequest();
    };

    useEffect(() => {
        const cook:beneficiaryRequestType = {
            department: activeRequest.department,
            type: activeRequest.type,
            status: activeRequest.status,
            additional: activeRequest.additional,
            assigned_to: activeRequest.assigned_to,
            documents: activeRequest.documents,
            id: activeRequest.id
        }
        methods.reset(cook);
    }, [activeRequest]);

    const methods = useForm({
        defaultValues: beneficiaryRequestDefaultValues,
        //@ts-ignore
        resolver: yupResolver(beneficiaryRequestValidations),
        mode: "onSubmit",
    });

    const cp = methods.watch("documents");
    const selectedDepartment = methods.watch("department");

    useEffect(() => {
        if (!selectedDepartment) return;
        const options = safe_get(categories, `${selectedDepartment}`, []);
        const newSchems =
            options.length > 0
                ? options.map((x) => ({
                    label: x,
                    value: x,
                }))
                : [];
        if (JSON.stringify(schems) !== JSON.stringify(newSchems)) {
            setSchems(newSchems);
        }
    }, [selectedDepartment]);

    const uploadFilesTobucket = (e) => {
        htmlValidation(e);
        Array.from(cp).forEach((fileToUpload, index) => {
            setTimeout(async () => {
                const uploadedData = await uploadFileToS3(
                    path,
                    fileToUpload,
                    "urn-assets"
                );

                if (uploadedData?.status === 204) {
                    setUploadedFile([
                        {
                            path: uploadedData.key,
                            type: fileToUpload["type"],
                            name: fileToUpload["name"],
                        },
                    ]);
                } else {
                    toast.error("Please try again later");
                }
            }, 1000 * index);
        });
    };

    const delete_file = (name) => {
        const updated_values = remove_item_from_array(uploadedFile, "path", name);
        setUploadedFile(updated_values);
    };

    const onsubmit = async (data) => {
        const tempReq = _.clone(beneficiaryStoreV2.requests)
        let idx = tempReq.findIndex((ele) => ele.id === data.id);
        const time_stamp = moment().format("DD/MM/YYYY HH:mm:ss");
        if (idx !== -1) {
            tempReq.splice(idx, 1, data);
        } else {
            tempReq.push({...data, id: makeid_alpa_numeric(6)});
        }
        beneficiaryStore.update_add_request_modal(false);
        beneficiaryStoreV2.update_requests(tempReq);
        methods.reset();
        setUploadedFile([]);
    };

    return (
        <>
            <div className="flex flex-row items-center justify-between">
                <div className="text-indigo-500 font-bold text-xl fp-text">
                    {t("add_request")}
                </div>
                <IconButton
                    color={"primary"}
                    size={"large"}
                    aria-label="add new request"
                    onClick={addNewRequestHandler}
                    disableRipple
                    sx={{padding: "5px"}}
                >
                    <i className={"fas fa-plus-circle"}/>
                </IconButton>
            </div>
            <TailwindModal isOpen={add_request_modal}>
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="p-4 flex flex-col">
                        <div className="text-xl font-semibold text-gray-900 fp-text ">
                            {t("add_request")}
                        </div>
                        <FormProvider {...methods}>
                            <form
                                onSubmit={e => {
                                    htmlValidation(e)
                                    methods.handleSubmit(onsubmit)(e)
                                }}
                                className="bg-white py-2 rounded-sm flex flex-col gap-y-3"
                            >
                                <RNSelect
                                    label={"Department"}
                                    name={"department"}
                                    options={departments}
                                />

                                <RNSelect
                                    label={"Schemes"}
                                    name={"type"}
                                    options={
                                        Array.isArray(schems) && schems.length > 0 ? schems : []
                                    }
                                />

                                <RNSelect
                                    label={"Request Status"}
                                    name={"status"}
                                    options={beneficRequestStatus}
                                />
                                <RNInput
                                    label={"Additional Information"}
                                    name={"additional"}
                                    multiline
                                    rows={3}
                                    fullWidth
                                />

                                <RequestAssigned
                                    name={"assigned_to"}
                                    options={concernedPersons}
                                />

                                <div className="w-full mb-4">
                                    <Stack
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Stack>
                                            <FileInputField
                                                label={"File"}
                                                accept={"*/*"}
                                                name={"documents"}
                                            />
                                        </Stack>
                                        <Stack justifyContent="flex-end" direction={"row"}>
                                            <VortexSpinner visible={isUploading}/>
                                            <UploadButton uploadHandler={uploadFilesTobucket}/>
                                        </Stack>
                                    </Stack>
                                    <RenderUploadedFile files={uploadedFile} onDelete={delete_file}/>
                                </div>
                                <FormButtons
                                    onSave={() => {
                                    }}
                                    onCancel={(e) => {
                                        beneficiaryStore.update_add_request_modal(false);
                                        methods.reset();
                                    }}
                                    type={"submit"}
                                />
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </TailwindModal>
        </>
    );
});

const RenderUploadedFile = ({files, onDelete}) => {
    if(files.length <= 0) return;
    return (
        <Stack>
            {files.length > 0
                ? files.map((x, index) => {
                    return (
                        <div
                            key={index}
                            className="flex flex-row items-center px-3 justify-between lg:w-7/12 "
                        >
                            {file_icon(x.type.split("/")[0])}
                            <p className="pl-2 lg:mr-5">
                                {x.path.split("/")[3]}
                            </p>
                            <i
                                className="fa-solid fa-trash-can text-red-600 px-4"
                                onClick={() => {
                                    onDelete(x.path);
                                }}
                            />
                        </div>
                    );
                })
                : null}
        </Stack>
    )
}
