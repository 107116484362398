import React, { useEffect, useState } from "react";
import { SubHeading, useStore } from "../../helpers";
import {
  FileInputField,
  RNInput,
  RNSelect,
} from "../../components/form-components";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { addEditGrievanceFormSchema } from "./addEditGrievanceFormSchema";
import { AddEngineer } from "./add-engineer";
import { FormButtons, UploadButton } from "../../components";
import { toast } from "react-toastify";
import { useGrievanceOptions } from "./Hooks/useGrievanceOptions";
import { useGrievanceUtilFunctions } from "./Hooks/useGrievanceUtilFunctions";
import { useCreateGrievance } from "./Hooks/useCreateGrievance";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { useUpdateGrievance } from "./Hooks/useUpdateGrievance";
type AddEditGrievanceV2Props = {
  closeModal: () => void;
  isEditMode: boolean;
  dataToEdit?: object;
};
//---------------------------------------------------------------------------------
export const AddEditGrievanceV2: React.FC<AddEditGrievanceV2Props> = observer(
  ({ closeModal, isEditMode, dataToEdit = {} }) => {
    //--------------------------------------DECLARATIONS-------------------------------------------
    const rootStore = useStore();
    const { projectid } = useParams();
    const { grievanceStore, voterListStore, flexiStore } = rootStore;

    //--------------------------------DATA INITIALIZATION-------------------------------------------------

    const {
      authorityPerson,
      departmentOptions,
      complaintOptions,
      hobli_options,
      priorityOptions,
      statusOptions,
      villageOptions,
      genderOptions,
      casteOptions,
      concerned_office_persons_options,
      beneficiary,
      setSelectedDept,
      setSelectedHobli,
      setConcernedDept,
      onEngSave,
    } = useGrievanceOptions();
    const { defaultValuesGetter } = useGrievanceUtilFunctions();
    const {
      mutate: createBeneficiary,
      isLoading,
      isError,
    } = useCreateGrievance();
    const {
      mutate: updateBeneficiary,
      isLoading: isUpdateLoading,
      isError: isUpdateError,
    } = useUpdateGrievance();
    // if (isEditMode) {
    //   console.log(defaultValuesGetter(dataToEdit));
    // }
    const defaultValues = defaultValuesGetter(dataToEdit);
    const methods = useForm({
      resolver: yupResolver(addEditGrievanceFormSchema),
      defaultValues: isEditMode ? { ...defaultValues } : {},
    });

    // useEffect(() => {
    //   methods.reset({ ...defaultValues });
    // }, [isEditMode, dataToEdit]);

    //---------------------------------STATES------------------------------------------------
    const [defaultAttachments, setDefaultAttachments] = useState([]);
    const [defaultRichMedia, setDefaultRichMedia] = useState([]);

    const [
      department,
      dob,
      complaint_type,
      concerned_department,
      attachments,
      media,
    ] = methods.watch([
      "department",
      "dob",
      "complaint_type",
      "concerned_department",
      "attachments",
      "media",
    ]);

    //------------------------------------USEEFFECTS---------------------------------------------
    useEffect(() => {
      if (isEditMode) {
        setDefaultAttachments([...defaultValues.attachments]);
        setDefaultRichMedia([...defaultValues.media]);
      }
    }, []);
    useEffect(() => {
      if (!isEditMode) {
        methods.setValue("complaint_type", complaintOptions[0]?.value);
      }
    }, [complaintOptions]);
    useEffect(() => {
      if (!isEditMode) {
        methods.setValue("village", villageOptions[0]?.value);
      }
    }, [villageOptions]);

    useEffect(() => {
      setConcernedDept(concerned_department);
    }, [concerned_department]);
    useEffect(() => {
      const enteredDate = moment(dob);
      const currentDate = moment();
      const diffInYears = currentDate.diff(enteredDate, "years");

      methods.setValue("age", diffInYears);
    }, [dob]);
    useEffect(() => {
      if (methods.formState.isSubmitSuccessful) {
        closeModal();
      }
    }, [methods.formState.isSubmitSuccessful]);
    //----------------------------------------UTILFUNCTIONS-----------------------------------------
    const { attachmentUploadHandler, addPayloadGetter, editPayloadGetter } =
      useGrievanceUtilFunctions();
    const deleteMediaFile = (file) => {
      setDefaultRichMedia((prevValues) => {
        return prevValues.filter((item) => item.id !== file.id);
      });
    };
    const deleteAttachmentFile = (file) => {
      setDefaultAttachments((prevValues) => {
        return prevValues.filter((item) => item.id !== file.id);
      });
    };

    //-------------------------------HOOK FORM METHODS--------------------------------------------------
    async function onSubmit(data) {
      if (isEditMode) {
        const formData = await addPayloadGetter(
          data,
          beneficiary,
          villageOptions,
          defaultAttachments,
          defaultRichMedia
        );
        if (formData) {
          const { r1Payload, summaryPayload } = editPayloadGetter(
            formData.properties,
            dataToEdit
          );
          console.log(r1Payload);

          updateBeneficiary({ r1Payload, summaryPayload });
        }
      } else {
        const payload = await addPayloadGetter(
          data,
          beneficiary,
          villageOptions
        );
        console.log(payload);
        try {
          if (payload) {
            createBeneficiary(payload);
          } else {
            toast.error("Upload the files");
            methods.resetField("attachments");
            methods.resetField("media");
            throw new Error("Not all attachments were uploaded");
          }
        } catch (error) {
          toast.error("something went wrong !");

          console.log(error);
        }

        voterListStore.update_active_voter({});
        grievanceStore.update_grievance_store_property("selected_voters", []);
        closeModal();
      }
    }
    function onError(data) {
      console.log(data);
    }
    //---------------------------------------------------------------------------------
    return (
      <div>
        <AddEngineer onEngSave={onEngSave} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <SubHeading title={"Grievance Information"} />
            <RNInput
              label={"Complaint Title"}
              name={"complaint_title"}
              onBlur={() => methods.trigger("complaint_title")}
              fullWidth
            />
            {/* Department - Custom Select Box */}
            <RNSelect
              label={"Department"}
              name={"department"}
              options={departmentOptions}
              onBlur={() => methods.trigger("department")}
              defaultValue={{
                label: defaultValues.department,
                value: defaultValues.department,
              }}
              onChange={(selectedOption) => {
                setSelectedDept(selectedOption.value);
                methods.setValue("department", selectedOption.value);
              }}
              fullWidth
            />
            {department && (
              <RNSelect
                label={"Complaint Type"}
                name={"complaint_type"}
                options={complaintOptions}
                defaultValue={{
                  label: defaultValues.complaint_type,
                  value: defaultValues.complaint_type,
                }}
                onBlur={() => methods.trigger("complaint_type")}
                fullWidth
              />
            )}
            <br />
            {complaint_type === "Other" && (
              <RNInput
                label={"Specific Complaint"}
                name={"specific_complaint"}
                onBlur={() => methods.trigger("specific_complaint")}
                fullWidth
              />
            )}
            {/* Hobli - Custom Select Box */}
            <RNSelect
              label={"Hobli"}
              name={"hobli"}
              options={hobli_options}
              defaultValue={{
                label: defaultValues.hobli,
                value: defaultValues.hobli,
              }}
              onChange={(selectedHobli) => {
                setSelectedHobli(selectedHobli.value);
                methods.setValue("hobli", selectedHobli.value);
              }}
              onBlur={() => methods.trigger("hobli")}
              fullWidth
            />
            {/* Village - Custom Select Box */}
            <RNSelect
              label={"Village"}
              name={"village"}
              options={villageOptions}
              defaultValue={{
                label: defaultValues.village,
                value: defaultValues.village,
              }}
              onBlur={() => methods.trigger("village")}
              fullWidth
            />
            <br />
            {/* More Details - Custom Text Area */}
            <RNInput
              label={"More Details"}
              name={"more_details"}
              multiline
              rows={4}
              onBlur={() => methods.trigger("more_details")}
              fullWidth
            />
            {/* Status - Custom Select Box */}
            <RNSelect
              label={"Status"}
              name={"status"}
              options={statusOptions}
              onBlur={() => methods.trigger("status")}
              fullWidth
            />
            {/* Priority - Custom Select Box */}
            <RNSelect
              label={"Priority"}
              name={"priority"}
              options={priorityOptions}
              onBlur={() => methods.trigger("priority")}
              fullWidth
            />
            <hr />
            {/* Office Person Form */}
            <SubHeading title={"Office Person"} />

            <RNSelect
              label={"Concerned Person"}
              name={"concerned_person"}
              options={concerned_office_persons_options}
              onBlur={() => methods.trigger("concerned_person")}
              fullWidth
            />
            <RNSelect
              label={"Concerned Department"}
              name={"concerned_department"}
              options={departmentOptions}
              onBlur={() => methods.trigger("concerned_department")}
              onChange={(selectedOption) => {
                console.log(typeof selectedOption.value);
                grievanceStore.update_grievance_store_property(
                  "selectedDepartment",
                  selectedOption.value
                );
                methods.setValue("concerned_department", selectedOption.value);
              }}
              fullWidth
            />
            {concerned_department && (
              <>
                <RNSelect
                  label={"Authority Person"}
                  name={"authority_person"}
                  options={authorityPerson}
                  fullWidth
                />

                <a
                  className="link link-hover fp-text underline text-sm font-bold text-right"
                  onClick={() => {
                    // grievanceStore.udpate_add_engineer_modal(true)
                    grievanceStore.update_grievance_store_property(
                      "isAddNewEnginner",
                      true
                    );
                  }}
                >
                  {"Add Engineer / Authority person"} +
                </a>
              </>
            )}
            <hr />
            <SubHeading title={"User Details"} />
            <RNInput
              label={"Name"}
              name={"person_name"}
              onBlur={() => methods.trigger("person_name")}
              fullWidth
            />
            <br />
            {/* Phone Number */}
            <RNInput
              label={"Phone Number"}
              name={"phone_number"}
              onBlur={() => methods.trigger("phone_number")}
              fullWidth
            />
            {/* Whatsapp Number */}
            <br />
            <RNInput
              label={"Whatsapp Number"}
              name={"whatsapp_number"}
              onBlur={() => methods.trigger("whatsapp_number")}
              fullWidth
            />
            {/* Voter ID */}
            <br />
            <RNInput
              label={"Voter ID"}
              name={"voter_id"}
              onBlur={() => methods.trigger("voter_id")}
              fullWidth
            />
            <br />
            {/* Aadhar ID */}
            <RNInput
              label={"Aadhar ID"}
              name={"aadhar_id"}
              onBlur={() => methods.trigger("aadhar_id")}
              fullWidth
            />
            <br />
            {/* Date Of Birth */}
            <RNInput
              label={"Date of Birth"}
              name={"dob"}
              type="date" // Custom input type as date
              onBlur={() => methods.trigger("dob")}
              fullWidth
            />
            {/* Age */}
            <br />
            <RNInput
              label={"Age"}
              name={"age"}
              disabled
              type="text" // Custom input type as number
              defaultValue={0}
              fullWidth
            />
            {/* Address */}
            <br />
            <RNInput
              label={"Address"}
              name={"address"}
              multiline
              rows={4}
              onBlur={() => methods.trigger("address")}
              fullWidth
            />
            {/* Caste (Custom Select) */}
            <br />
            <RNSelect
              label={"Caste"}
              name={"caste"}
              options={casteOptions}
              onBlur={() => methods.trigger("caste")}
              fullWidth
            />
            {/* Gender (Custom Select) */}
            <RNSelect
              label={"Gender"}
              name={"gender"}
              options={genderOptions}
              onBlur={() => methods.trigger("gender")}
              fullWidth
            />
            <hr />
            <SubHeading title={"Attachements"} />
            <div className="flex flex-row flex-wrap justify-between align-middle">
              <FileInputField
                name={"attachments"}
                accept={".pdf, .doc, .docx, .txt"}
              />
              <UploadButton
                uploadHandler={() => {
                  attachmentUploadHandler(attachments, "attachment")
                    .then((res) => console.log(res))
                    .catch((e) => console.log(e));
                }}
              />
            </div>
            {isEditMode
              ? defaultAttachments.map((item) => {
                  let attachments_path = item.path;
                  attachments_path = attachments_path.split("/");
                  let file_name = attachments_path.at(-1);
                  return (
                    <div className="m-2 p-2 bg-gray-200 rounded-lg max-w-max">
                      <i className="fa-solid fa-file p-2"></i>
                      {file_name}
                      <i
                        className="ml-4 fa-solid fa-trash p-2 text-red-500 cursor-pointer"
                        onClick={() => deleteAttachmentFile(item)}
                      ></i>
                    </div>
                  );
                })
              : ""}
            <hr />
            <SubHeading title={"Media"} />
            <div className="flex flex-row flex-wrap justify-between align-middle">
              <FileInputField
                name={"media"}
                accept={
                  "image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*"
                }
              />
              <UploadButton
                uploadHandler={() => {
                  attachmentUploadHandler(media, "media")
                    .then((res) => console.log(res))
                    .catch((e) => console.log(e));
                }}
              />
            </div>
            {isEditMode
              ? defaultRichMedia.map((item) => {
                  let media_path = item.path;
                  media_path = media_path.split("/");
                  let file_name = media_path.at(-1);
                  return (
                    <div className="m-2 p-2 bg-gray-200 rounded-lg max-w-max">
                      <i className="fa-solid fa-file p-2"></i>
                      {file_name}
                      <i
                        className="ml-4 fa-solid fa-trash p-2 text-red-500 cursor-pointer"
                        onClick={() => deleteMediaFile(item)}
                      ></i>
                    </div>
                  );
                })
              : ""}
            <br />
            <FormButtons
              onSave={() => {
                !isLoading &&
                  !isError &&
                  methods.formState.isSubmitSuccessful &&
                  closeModal();
              }}
              onCancel={() => {
                methods.reset && closeModal();
              }}
            />
          </form>
        </FormProvider>
      </div>
    );
  }
);
