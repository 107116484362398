import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {
    FormButtons,
    LoadingState,
    SideOverlay,
    UploadButton,
} from "../../components";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {observer} from "mobx-react-lite";
import {Grid, Stack} from "@mui/material";
import {safe_get} from "../../report-visuals";
import {projectWorkSummaryDefaultValues} from "./pw-v2-types";
import {VortexSpinner} from "../digital-repository";
import {htmlValidation, useFileUpload, useStore} from "../../helpers";
import {
    RNInput,
    FileInputField,
    RNSelect,
    RNBoothSelector,
} from "../../components/form-components";
import {useAddProjectWork, useEditProjectWork} from "./pw-hooks";
import {yupResolver} from "@hookform/resolvers/yup";
import {addProjectWorkValidations} from "./utils";

export const AddProjectWork = observer((props: any) => {

    const {projectid} = useParams();
    const PATH = `project-${projectid}/projectworks/cover-photos`;
    const {projectWorkStoreV2, projectStore} = useStore();
    const {uploadFileToS3, isUploading} = useFileUpload();
    const {addProjectWork, isLoading} = useAddProjectWork();
    const {isLoading: isEditing, editProjectWork} = useEditProjectWork();
    const {activeProjectWork, add_project_modal} = projectWorkStoreV2;
    const [uploadedFile, setUploadedFile] = useState([]);

    const projectWorkId = safe_get(activeProjectWork, "id", null);
    const projectWorksConfig = projectStore.getProjectProperty(
        "project_works",
        {}
    );
    const statusOptions = safe_get(projectWorksConfig, "status_config", []).map(
        (x) => {
            return {label: x.status, value: x.status};
        }
    );

    const methods = useForm({
        defaultValues: projectWorkSummaryDefaultValues,
        //@ts-ignore
        resolver: yupResolver(addProjectWorkValidations),
        mode: "onSubmit",
    });

    const cp = methods.watch("coverPhotoPath");

    useEffect(() => {
        methods.reset(
            safe_get(activeProjectWork, "summary", projectWorkSummaryDefaultValues)
        );
        const file = safe_get(activeProjectWork, "summary.coverPhotoPath", {});
        setUploadedFile([...uploadedFile, file]);
        methods.setValue("coverPhotoPath", [file]);
    }, [activeProjectWork]);

    const close_modal = async () => {
        projectWorkStoreV2.update_add_project_modal(false);
        methods.reset(projectWorkSummaryDefaultValues);
    };

    const onSubmit = async (data) => {
        const final = {...data, coverPhotoPath: uploadedFile[0]};
        if (uploadedFile.length <= 0) {
            toast.warning("Please upload the profile Photo");
            return;
        }
        if (projectWorkId) {
            editProjectWork({
                ...activeProjectWork,
                summary: {
                    ...data,
                    coverPhotoPath: uploadedFile[0],
                },
            });
        } else {
            addProjectWork(final);
            projectWorkStoreV2.update_project_work_property(
                "activeProjectWork",
                final
            );
        }
        // props.onAdd(final)
    };

    const profilePhotoUploadHandler = async (e) => {
        htmlValidation(e);
        Array.from(cp).forEach((fileToUpload, index) => {
            setTimeout(async () => {
                const uploadedData = await uploadFileToS3(
                    PATH,
                    fileToUpload,
                    "urn-assets"
                );
                if (uploadedData?.status === 204) {
                    setUploadedFile([
                        {
                            path: uploadedData.key,
                            type: fileToUpload["type"],
                            name: fileToUpload["name"],
                        },
                    ]);
                    toast.success("Profile Photo uploaded");
                } else {
                    toast.error("Please try again later");
                }
            }, 1000 * index);
        });
    };

    if (isLoading || isEditing) return <LoadingState/>;

    return (
        <>
            <SideOverlay
                onClose={close_modal}
                show={add_project_modal}
                title={!projectWorkId ? "Add New Project Work" : "Update Project Work"}
            >
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4"
                    >
                        <RNInput label={"Name"} name={"name"}/>
                        <RNInput
                            label={"Description"}
                            name={"description"}
                            multiline
                            rows={3}
                            fullWidth
                        />

                        <RNInput
                            label={"Start Date"}
                            name={"startDate"}
                            type="date"
                            InputLabelProps={{shrink: true}}
                        />

                        <RNInput
                            label={"Estimated Completion Date"}
                            name={"estimatedCompletionDate"}
                            type="date"
                            InputLabelProps={{shrink: true}}
                        />

                        <RNInput label={"Sublocation"} name={"sublocation"}/>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <RNInput
                                    label={"Latitude"}
                                    type="number"
                                    name={"latitude"}
                                    inputProps={{step: "any"}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <RNInput
                                    label={"Longitude"}
                                    type="number"
                                    name={"longitude"}
                                    inputProps={{step: "any"}}
                                />
                            </Grid>
                        </Grid>

                        <RNSelect
                            label={"Status"}
                            name={"status"}
                            options={statusOptions}
                        />
                        <RNBoothSelector
                            label={"Booth/Village"}
                            name={"booth"}
                            isMulti={true}
                            selector={"display"}
                        />

                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Stack>
                                <FileInputField
                                    label={"Profile Photo"}
                                    accept={"image/*"}
                                    name={"coverPhotoPath"}
                                />
                            </Stack>
                            <Stack justifyContent="flex-end" direction={"row"}>
                                <VortexSpinner visible={isUploading}/>
                                <UploadButton uploadHandler={profilePhotoUploadHandler}/>
                            </Stack>
                        </Stack>
                        <Stack>
                            {uploadedFile.length > 0
                                ? uploadedFile.map((file) => <p>{file.path}</p>)
                                : null}
                        </Stack>

                        <FormButtons onSave={() => {
                        }} onCancel={close_modal}/>
                    </form>
                </FormProvider>
            </SideOverlay>
        </>
    );
});
