import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useRef} from "react";
import {useStore} from "../helpers";
import {useNavigate, useParams, Outlet} from "react-router-dom";
import {safe_get} from "../report-visuals";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {BreadCrumbs, LoadingState, ProjectHeader, SideOverlay, UpdateProfile,} from "../components";
import {useGetFullProject} from "../custom-hooks";
import {useQuery} from "@tanstack/react-query";

// Default configuration for web features, indicating which features are enabled or disabled.
// This is used as a fallback for existing projects that may not have this property.
const default_web_features = {
    beneficiary: "disabled",
    grievance: "enabled",
    project_works: "enabled",
    c3: "enabled",
    manage_voters: "enabled",
    voter_reach_out: "enabled",
    election_day_dashboard: "disabled",
    voter_registration_tracker: "disabled",
    field_payments: "disabled",
    field_team_report: "enabled",
    manage_project: "enabled",
    user_management: "enabled",
    taskmanager: "enabled",
    digital_repository: "enabled",
    beneficiary_v2: "disabled",
    influential_leaders: "disabled",
    grievance_V2: "disabled",
    analytical_dashboard: "disabled",
};

export const ProjectDashboardPage = observer((props) => {

    const rootStore = useStore();
    const navigate = useNavigate();
    const {projectid} = useParams();
    const {userStore, projectStore, surveyResponseStore, digitalArchiveStore, flexiStore} = rootStore;
    const role = userStore.user["role"];
    const [t] = useTranslation("ProjectDashboard");
    const track_voter_record_config = useRef({});
    const show_voter_reg_card = useRef(false);
    const view_task_permission = useRef(false);
    const {isLoading} = useGetFullProject();

    // Permissions for different project features.
    const view_beneficiary_permission = projectStore.getPermission(
        "manage_fs_beneficiary",
        false
    );
    const view_grievance_permission = projectStore.getPermission(
        "manage_fs_grievance",
        false
    );
    const view_projectworks_permission = projectStore.getPermission(
        "manage_fs_projectworks",
        false
    );

    const fetchData = async () => {
        return await rootStore.api.post('project/myprojects', {})
    }

    const {isLoading:projectsLoading} = useQuery({
        queryKey: ['projects-list'],
        queryFn: fetchData,
        onSuccess: data => {
            if(!projectid || projectid === "undefined") {
                const default_project_id = safe_get(data.data[0], "id", "1")
                navigate(`project/${default_project_id}`, {replace: true});
            }
        },
        enabled: projectid === "undefined" || projectid === undefined
    })

    // Get project-specific web features or fallback to default features.
    const webFeatures = projectStore.getProjectProperty(
        "web_features",
        default_web_features
    );
    console.log(webFeatures)
    const isRootOrClient = userStore.isRoot() || userStore.isClient();

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: true},
    ];

    useEffect(() => {
        if (!isLoading) {
            // Fetch configuration for tracking voter records and set related states.
            track_voter_record_config.current = projectStore.getProjectProperty(
                "track_voter_reg_eci",
                {}
            );
            show_voter_reg_card.current = safe_get(
                track_voter_record_config,
                "enabled",
                false
            );
            view_task_permission.current = projectStore.getPermission(
                "view_tasks",
                false
            );

            // Reset filters and digital archive store properties.
            surveyResponseStore.reset_new_filters();
            digitalArchiveStore.update_DAStoreProp("activeParentId", "");
        }

        // Reset page number in flexiStore whenever the project changes.
        flexiStore.reset_fsd_search();
    }, [projectid]);

    // useMemo hook to memoize the menu list generation based on project features.
    const populateMenuList = useMemo(() => {

        const pm = {id: 1, heading: t("project_management"), subItems: []};
        const fw = {id: 2, heading: t("field_work"), subItems: []};
        const cm = {id: 3, heading: t("management"), subItems: []};

        // Loop through each feature in webFeatures and populate the menu sections based on the feature status.
        for (let key of Object.keys(webFeatures)) {
            const val = webFeatures[key];
            if (val === "enabled") {
                switch (key) {
                    case "beneficiary":
                        if (view_beneficiary_permission || isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-hands-helping text-green-600",
                                name: t("beneficiary"),
                                path: "/project/" + projectid + "/beneficiary_management",
                            });
                        }
                        break;
                    case "grievance":
                        if (view_grievance_permission || isRootOrClient) {
                            cm.subItems.push({
                                icon: "fas fa-list-ul text-teal-600",
                                name: t("grievance"),
                                path: "/project/" + projectid + "/grievance_management",
                            });
                        }
                        break;
                    case "grievance_management_v2":
                        if (view_grievance_permission || isRootOrClient) {
                            cm.subItems.push({
                                icon: "fas fa-face-sad-tear text-teal-600",
                                name: "Grievance management V2",
                                path: "/project/" + projectid + "/grievance_management_v2",
                            });
                        }
                        break;
                    case "project_works":
                        if (isRootOrClient || view_projectworks_permission) {
                            cm.subItems.push({
                                icon: "fa-solid fa-diagram-project text-teal-600",
                                name: t("project"),
                                path: "/project/" + projectid + "/project_works",
                            });
                        }
                        break;
                    case "c3":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-grid-horizontal",
                                name: t("command"),
                                path: "/project/" + projectid + "/c3",
                            });
                        }
                        break;
                    case "analytical_dashboard":
                        if (isRootOrClient && projectid == "621") {
                            cm.subItems.push({
                                icon: "fa-solid fa-chart-simple text-orange-600",
                                name: "Analytial Dashboard",
                                path: "/dashboard/kopargaon",
                            });
                        }
                        break;
                    case "manage_voters":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fas fa-poll-people text-teal-600",
                                name: t("manage"),
                                path: "/project/" + projectid + "/manage-voters",
                            });
                        }
                        break;
                    case "voter_reach_out":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-comments text-teal-600",
                                name: t("reach"),
                                path: "/project/" + projectid + "/voter_reach_out",
                            });
                        }
                        break;
                    case "election_day_dashboard":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-table text-teal-600",
                                name: t("election"),
                                path: "/project/" + projectid + "/election_day_dashboard",
                            });
                        }
                        break;
                    case "voter_registration_tracker":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-person-booth text-teal-600",
                                name: t("tracker"),
                                path: "/project/" + projectid + "/voter_registration_tracker",
                            });
                        }
                        break;
                    case "digital_repository":
                        if (isRootOrClient) {
                            cm.subItems.push({
                                icon: "fa-solid fa-person-booth text-teal-600",
                                name: t("Digital Repository"),
                                path: "/project/" + projectid + "/digital_repository",
                            });
                        }
                        break;
                    case "booth_selection":
                        if (isRootOrClient) {
                            fw.subItems.push({
                                icon: "fa-solid fa-person-booth text-teal-600",
                                name: "Booth Selection",
                                path: "/project/" + projectid + "/booth_selection",
                            });
                        }
                        break;
                    case "field_payments":
                        const WHITELIST_FOR_USERS = [
                            9669, 9174, 74, 1, 92, 10693, 292, 11717, 11780,
                        ];
                        const has_permission = WHITELIST_FOR_USERS.includes(
                            userStore.user.id
                        );
                        if (has_permission) {
                            fw.subItems.push({
                                icon: "fas fa-wallet",
                                name: t("field_pay"),
                                path: "/project/" + projectid + "/fieldpayment",
                            });
                        }
                        break;
                    case "field_team_report":
                        fw.subItems.push({
                            icon: "fas fa-file-chart-line text-blue-600",
                            name: t("team_report"),
                            path: "/project/" + projectid + "/fieldteamreport",
                        });
                        break;
                    case "taskmanager":
                        // Adding Taskmanager card if the user has permission to view tasks or is root/client.
                        if (
                            view_task_permission.current ||
                            role === "root" ||
                            role === "client"
                        ) {
                            fw.subItems.push({
                                icon: "fa-solid fa-list-check text-blue-600",
                                name: t("task"),
                                path: "/project/" + projectid + "/taskmanager",
                            });
                        }
                        break;
                    case "manage_project":
                        // Only root users can access project management.
                        if (userStore.isRoot() || userStore.user.id === 16003) {
                            pm.subItems.push({
                                icon: "fa-solid fa-cogs text-indigo-600",
                                name: t("manage_project"),
                                path: `/project/${projectid}/manage`,
                            });
                        }
                        break;
                    case "user_management":
                        if (userStore.isRoot() || userStore.isClient()) {
                            pm.subItems.push({
                                icon: "fa-solid fa-users text-yellow-600",
                                name: t("user_manage"),
                                path: "/project/" + projectid + "/usermanagement",
                            });
                        }
                        break;
                    case "beneficiary_v2":
                        if (userStore.isRoot() || userStore.isClient()) {
                            cm.subItems.push({
                                icon: "fa-solid fa-users text-yellow-600",
                                name: "Beneficiary V2",
                                path: "/project/" + projectid + "/beneficiary_v2",
                            });
                        }
                        break;
                    case "project_works_v2":
                        if (userStore.isRoot() || userStore.isClient()) {
                            cm.subItems.push({
                                icon: "fa-solid fa-users text-yellow-600",
                                name: "PW V2",
                                path: "/project/" + projectid + "/projectworks_v2",
                            });
                        }
                        break;
                    case "influential_leaders":
                        if (userStore.isRoot() || userStore.isClient()) {
                            cm.subItems.push({
                                icon: "fas fa-user-friends text-orange-600",
                                name: "Influential Leaders",
                                path: "/project/" + projectid + "/influential_leaders",
                            });
                        }
                        break;
                    // Additional cases can be added here as needed.
                }
            }
        }
        return [cm, fw, pm];
    }, [webFeatures, projectid]);

    if (isLoading) return <LoadingState/>;

    return (
        <>
            <BreadCrumbs crumbs={crumbs}/>
            <ProjectHeader/>

            <div className="px-4 flex flex-col gap-y-2 sm:gap-y-6 ">
                {!isLoading &&
                _.sortBy(populateMenuList, (item) => item.id).map(
                    (featureHead, index) => {
                        const {heading, subItems} = featureHead;
                        if (subItems.length == 0) {
                            return null; // Skip rendering if there are no sub-items.
                        }
                        return (
                            <div key={index} className="flex flex-col">
                                <h2 className="title px-2 ">{heading}</h2>
                                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-2">
                                    {subItems.map((item, index) => (
                                        <div
                                            key={index}
                                            className="p-2 cursor-pointer"
                                            onClick={(e) => navigate(item.path)}
                                        >
                                            <div
                                                className="p-4 flex flex-nowrap flex-row shadow-md rounded-lg bg-white hover:shadow-xl items-center">
                                                <div
                                                    className="rounded-full bg-blue-200 text-4xl h-20 w-20 flex items-center justify-center">
                                                    <i className={item.icon}/>
                                                </div>
                                                <h3 className="ml-4 font-bold">{item.name}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }
                )}
            </div>

            {/* Render any nested routes */}
            <Outlet/>
        </>
    );
});
