import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import {useStore} from "../../helpers";
import TeamDetails from "./team-details";
import {AddTeam} from "./add-team";
import {useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {BreadCrumbs, CreateModel, LoadingState, ProjectHeader, SideOverlay} from "../../components";
import {useGetFullProject, useIsMobile} from "../../custom-hooks";
import {useQuery} from "@tanstack/react-query";
import UsersList from "./users-list";
import {AddNewUsers} from "./add-users";
import {FsFilterPane} from "../../flexistore/fsFilterPane";
import AsyncSelect from "react-select/async";
import {MagnifyingGlass} from "react-loader-spinner";
import {Typography} from "@mui/material";
import {UserDetails} from "./user-details";


export const UserManagement = observer((props) => {

    const rootStore = useStore();
    const isMobile = useIsMobile();
    const {userStore, projectStore, voterListStore} = rootStore;
    const {editTeam, addTeam} = userStore;
    const {showVoterlist, FilterStatus} = voterListStore;
    const {projectid} = useParams();
    const selectedTagsRef = useRef(null)

    const crumbs = [
        {name: "DashBoard", href: `/project/${projectid}`, current: false},
        {name: "User Management", href: "#", current: true},
    ];

    const [query, setQuery] = useState("");
    const [userData, setUserData] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [userFilterData, setUserFilterData] = useState({});
    const [TeamList, setTeamList] = useState({});
    const userProfile = userStore.user;
    const role = userProfile["role"];
    const isAllowed = role === "root" || role === "client";
    const {t} = useTranslation(["user_management", "survey_management"]);

    const {isLoading:fullProjectLoading, refetch:reftechFullProject} = useGetFullProject();

    useEffect(() => {
        setTeamList(projectStore.getProjectProperty("user_hierarchy", {}))
    }, []);

    const getData = async (data: object) => {
        setUserFilterData({data});
    };

    const getTeamData = async (data: object) => {
        setTeamList(data);
    };

    const get_users = async () => {
        const payload = {
            projectid: parseInt(projectid),
            tags: selectedTags.map((x) => {
                return x["value"];
            }),
            query: query,
            limit: 500,
            offset: 0,
        };
        return await projectStore.getAllUsers(payload);
    }

    const {data, isLoading,isSuccess, refetch, isFetching} = useQuery(
        {
            queryKey: ["users", projectid],
            queryFn: get_users,
            onSuccess: data => {
                setUserData(data?.data);
                userStore.setUserList(data?.data["users"])
            }
        }
    )

    const fetchAllTags = async () => {
        const response = await projectStore.getAllUserTags({
            projectid: parseInt(projectid),
        });

        try {
            if (response.status === 200) {
                return response.data?.map(val => {
                    return {
                        value: val, label: val
                    }
                })
            } else {
                return []
            }
        } catch (e) {
            console.log(e)
        }
    };

    const handleAddTeamClose = () => {
        userStore.setAddTeamStatus(false);
    };

    const UserAttachVoterProfile = async () => {
        const payload = {
            userid: userStore.userId,
            voter: voterListStore.activeVoter,
        };

        const result = await voterListStore.attach_VoterProfile(payload);
        if (result.status === 200) {
            voterListStore.update_connect_user_to_voterlist_modal(false);
            voterListStore.setShowVoterlist(false);
            toast.success("Attached Successfully");
        }
    };

    const handleSearch = val => {
        setQuery(val);
        setTimeout(() => refetch(), 500)
    }

    if (isLoading || fullProjectLoading ) return <LoadingState/>;

    return (
        <>
            {isAllowed ?
                <>
                    {!isMobile ?
                        <>
                            <BreadCrumbs crumbs={crumbs}/>
                            <ProjectHeader/>
                        </>
                     : null}

                    <div className="px-4">
                        <div className="rounded-lg flex flex-col">
                            <div className={"flex flex-col sm:flex-row sm:items-center justigy-between gap-x-4"}>
                                <Typography variant={"h4"} color={"primary"} className={"flex flex-1"}>
                                    {t("user_management")}
                                </Typography>
                                <div className={"flex flex-col flex-1 gap-x-4 sm:flex-row sm:items-center sm:justify-between flex-wrap"}>
                                    {isFetching ? <MagnifyingGlass
                                        visible={true}
                                        height="50"
                                        width="50"
                                        ariaLabel="magnifying-glass-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="magnifying-glass-wrapper"
                                        glassColor="#c0efff"
                                        color="#e15b64"
                                    /> : null}
                                    <FsFilterPane onSearch={handleSearch}/>
                                    <div className=" w-full lg:w-1/2">
                                        <AsyncSelect
                                            ref={selectedTagsRef}
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={fetchAllTags}
                                            placeholder="Filter by tags..."
                                            className="w-full z-0"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "54px",
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <TeamDetails
                                userDetails={userData}
                                selectData={getData}
                                hydrate={reftechFullProject}
                                ActiveList={TeamList}
                            />

                            <UserDetails />
                            {isSuccess ? <UsersList users={data?.data?.users} onRefresh={refetch}/> : null}
                            <AddNewUsers onUserAdded={refetch}/>

                            <SideOverlay
                                onClose={handleAddTeamClose}
                                show={addTeam}
                                title={t("add_new")}
                            >
                                <AddTeam
                                    hydrate={reftechFullProject}
                                    setTeamData={getTeamData}
                                    userFilterData={userFilterData}
                                    userDetails={userData}
                                    edit={editTeam}
                                />
                            </SideOverlay>
                        </div>
                    </div>
                </>
            :
                <div className="p-4 text-md font-bold text-gray-500">
                    {t("permission")}
                </div>
            }

            {voterListStore.connect_user_to_voterlist_modal && (
                <CreateModel
                    isOpen={voterListStore.connect_user_to_voterlist_modal}
                    desc={`Are you sure you want to Attach this voter to UserProfile ?`}
                    onCreateClick={UserAttachVoterProfile}
                    onCancelClick={() => voterListStore.update_connect_user_to_voterlist_modal(false)}
                />
            )}
        </>
    );
});
