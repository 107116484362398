import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router";
import { useStore } from "../../helpers";
import { safe_get } from "../../report-visuals";
import { AgGridReact } from "@ag-grid-community/react";

const GrievanceTableV3 = observer(({ data }: { data }) => {
  const { projectid } = useParams();
  const rootStore = useStore();
  const navigate = useNavigate();
  const { projectStore } = rootStore;
  const grievanceConfig = projectStore.getProjectProperty("grievances", {});
  const statusConfig = safe_get(grievanceConfig, "status_config", []);

  const rowData = useMemo(() => {
    return data;
  }, [data]);
  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Tracking ID",
        field: "id",
        cellRenderer: (val) => (
          <>
            P{projectid}G{val?.data?.id}
          </>
        ),
        flex: 1,
      },
      {
        headerName: "Created Date",
        field: "created",
      },
      {
        headerName: "Name",
        field: "summary.reported_by.name",
        flex: 1,
      },
      {
        headerName: "Phone Number",
        field: "summary.reported_by.phone",
        flex: 1,
      },
      {
        headerName: "Complaint Title",
        field: "summary.title",
        flex: 1,
      },
      {
        headerName: "Department",
        field: "summary.dept",
        flex: 1,
      },
      {
        headerName: "Village",
        field: "summary.piv_level_1",
        flex: 1,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        cellRenderer: (val) => {
          const cell_data = val?.data?.summary?.current_status;
          let cell_data_style = statusConfig.find(
            (value) => value.status === cell_data
          );
          return (
            <p>
              <span
                style={{ backgroundColor: cell_data_style?.color }}
                className={`text-white text-xs font-medium pr-2 py-0.5 rounded border`}
              >
                <i className={`${cell_data_style?.icon} px-2`} />
                {cell_data}
              </span>
            </p>
          );
        },
      },
      {
        headerName: "Priority",
        field: "priority",
        flex: 1,
        cellRenderer: (val) => {
          const priority = val?.data?.summary?.priority;
          return (
            <span
              className={`${
                priority === "High"
                  ? "bg-red-500"
                  : priority === "Medium"
                  ? "bg-yellow-400"
                  : "bg-green-400"
              } text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded border`}
            >
              {val?.data?.summary?.priority}
            </span>
          );
        },
      },
      {
        headerName: "Concerned Person",
        field: "summary.assigned.name",
        flex: 1,
      },
    ];
  }, [data]);

  return (
    <div className="ag-theme-quartz" style={{ height: 800, width: "100%" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={20}
        domLayout="autoHeight"
        defaultColDef={{
          resizable: true,
        }}
        headerHeight={56}
        onRowClicked={(event) => {
          navigate(
            `/project/${projectid}/grievance_management_v2/${event?.data?.id}/view`
          );
        }}
      />
    </div>
  );
});

export default GrievanceTableV3;
