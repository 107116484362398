import {observer} from "mobx-react-lite";
import {useIsMobile} from "../../../custom-hooks";
import {remove_item_from_array, useStore} from "../../../helpers";
import {useTranslation} from "react-i18next";
import {safe_get} from "../../../report-visuals";
import {ViewAttachment} from "../../../components";
import React from "react";
import {RenderRequestStatus} from "../../beneficiary-management";


export const RenderBeneficiaryRequestsV2 = observer((props: any) => {

    const isMobile = useIsMobile();
    const {beneficiaryStore, beneficiaryStoreV2} = useStore();

    const {t} = useTranslation("beneficiary");
    const {add_beneficiary_modal,activeBeneficiary} = beneficiaryStore;
    const fsdid = safe_get(activeBeneficiary, "id", null)
    const req = fsdid ? safe_get(activeBeneficiary, "r1.requests", []) : safe_get(beneficiaryStoreV2, "requests", []);
    console.log({req})
    if (req.length <= 0) return null;

    return (
        <div className="my-2 bg-white py-2 rounded-lg">
            {!add_beneficiary_modal ? (
                <div className="flex flex-row items-center px-2">
                    <i className="fas fa-hand-holding-seedling text-xl pr-4 text-purple-500"/>
                    <h2 className="text-xl text-purple-500 font-bold py-2">
                        {t("requests")}
                    </h2>
                </div>
            ) : null}

            <ul role="list">
                {req.map((request) => {
                    const assigned_to = safe_get(request.assigned_to, "name", "");
                    return (
                        <li key={request.id}>
                            <div className="py-2 flex flex-col rounded-md bg-gray-100 m-2 px-2 mb-2 group">
                                <div className="flex justify-between items-center">
                                    <p className="text-indigo-600 font-bold flex-1">
                                        {request.type}
                                    </p>
                                    <div className="flex items-center justify-between">
                                        <RenderRequestStatus
                                            feature={"beneficiary"}
                                            status={request.status}
                                        />
                                        {add_beneficiary_modal ?(
                                            <div className="flex flex-row items-center justify-between">
                                                <i
                                                    className={`fas fa-pencil text-gray-600 px-2 cursor-pointer transition-opacity duration-800 ${
                                                        !isMobile ? "invisible group-hover:visible" : ""
                                                    }`}
                                                    onClick={() => {
                                                        beneficiaryStore.update_add_request_modal(true);
                                                        beneficiaryStore.setActiveRequest(request);
                                                    }}
                                                />
                                                <i
                                                    className={`fas fa-trash text-red-600 px-2 cursor-pointer transition-opacity duration-800 ${
                                                        !isMobile ? "invisible group-hover:visible" : ""
                                                    }`}
                                                    onClick={() => {
                                                        const updated_values = remove_item_from_array(beneficiaryStoreV2.requests, "id", request);
                                                        beneficiaryStoreV2.update_requests(updated_values)
                                                    }}
                                                />
                                            </div>
                                        ): null}
                                    </div>
                                </div>
                                <p>{request.additional}</p>
                                {assigned_to ? (
                                    <div>
                                        <div className={"font-bold text-gray-600"}>
                                            {t("this_request_assigned")}
                                        </div>
                                        <p className="text-indigo-600">
                                            {safe_get(request.assigned_to, "name", "N/A")}
                                        </p>
                                        <p className="text-indigo-600">
                                            {safe_get(request.assigned_to, "phone", "N/A")}
                                        </p>
                                        <p className="text-indigo-600">
                                            {safe_get(request.assigned_to, "designation", "N/A")}
                                        </p>
                                    </div>
                                ) : (
                                    <p className="text-gray-400 italic">
                                        {t("request_to_concerned_person")}
                                    </p>
                                )}
                                {/*{request?.documents?.length > 0 &&*/}
                                {/*    request.documents.map((doc) => {*/}
                                {/*        return (*/}
                                {/*            <ViewAttachment*/}
                                {/*                label={false}*/}
                                {/*                key={doc.id}*/}
                                {/*                attachment={doc}*/}
                                {/*                backgroundColor={""}*/}
                                {/*            />*/}
                                {/*        );*/}
                                {/*    })}*/}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});