import React, {useState} from "react";
import {useStore} from "../../helpers/helpers";
import {safe_get} from "../../report-visuals/report-utils";
import {FormButtons} from "../../components";
import {observer} from "mobx-react-lite";
import {DepartmentFilter, PriorityFilter, StatusFilter, VillageFilter} from "../../components/cm-filters";
import {Button, Stack} from "@mui/material";
import {C3ConcernedPerson} from "../constituency-command-center";

const defaultFilters = {
    dept: [],
    piv_level_2: [],
    current_status: [],
    priority: [],
    reported_by: [],
    office_persons: [],
}

export const BeneficiaryFilters = observer((props:any) => {

    const rootStore = useStore();
    const {projectStore, beneficiaryStore} = rootStore;
    const filterTypes = ["Department", "Village", "ConcernedPerson" ];
    const [activeFilter, setActiveFilter] = useState(filterTypes[0]);

    const beneficiery_property = projectStore.getProjectProperty("beneficiary", {});
    const village_key = safe_get(beneficiery_property, "more.village_selector", "piv_level2")
    const categories = safe_get(beneficiery_property, "categories", {});
    const status = safe_get(beneficiery_property, "status_config", []);
    const priority = safe_get(beneficiery_property, "priority", []);
    const departmentOptions = Object.keys(categories);

    const [beneficiarySelectedFilters, setBeneficiarySelectedFilters] = useState(beneficiaryStore.filters)

    const closeSideOverLay = () => {
        beneficiaryStore.setBeneficiarieFilter(false);
    }

    const handleFilterChange = (key, event) => {
        const {value, checked} = event.target;
        if (checked) {
            setBeneficiarySelectedFilters({
                ...beneficiarySelectedFilters,
                [key]: [...beneficiarySelectedFilters[key], value]
            });
        } else {
            setBeneficiarySelectedFilters({
                ...beneficiarySelectedFilters,
                [key]: beneficiarySelectedFilters[key].filter((e) => e !== value)
            })
        }
    }

    const handleVillageChange = (villages) => {
      setBeneficiarySelectedFilters({...beneficiarySelectedFilters, piv_level_2:villages })
    }

    const onFiltersSaveHandler = () => {
        closeSideOverLay();
        beneficiaryStore.updateFilters(beneficiarySelectedFilters);
    }

    const onPersonSelection = (booths: []) => {
        setBeneficiarySelectedFilters({ ...beneficiarySelectedFilters, office_persons:  booths })
    }

    return (
        <div className="w-full h-full flex">
            <div className="flex flex-col gap-y-2 p-2 w-1/4 h-full border-black border-r-2">
                {filterTypes.map((tab) => (
                    <p onClick={() => {
                        setActiveFilter(tab)
                    }}>
                        <span className={activeFilter == tab ? "text-purple-600 text-lg font-medium" : "text-lg font-medium"}>{tab}</span>
                    </p>
                ))}
            </div>
            <div key="beneficiary" className="flex flex-1 flex-col justify-between p-2 w-4/4 h-full w-full overflow-y-auto">
                <div className={"flex "}>
                    {activeFilter == "Department" && (
                        <DepartmentFilter
                            departmentOptions={departmentOptions}
                            value={beneficiarySelectedFilters.dept}
                            setValues={(event) => handleFilterChange("dept", event)}
                        />
                    )}

                    {activeFilter == "Village" &&
                        <VillageFilter
                            value={beneficiarySelectedFilters.piv_level_2}
                            setValues={(villages) => handleVillageChange(villages)}
                        />
                    }

                    {activeFilter == "Status" &&
                        <StatusFilter
                            data={status}
                            value={beneficiarySelectedFilters.current_status}
                            setValues={(event) => handleFilterChange("current_status", event)}
                        />
                    }

                    {activeFilter == "Priority" &&
                        <PriorityFilter
                            data={priority}
                            value={beneficiarySelectedFilters.priority}
                            setValues={(event) => handleFilterChange("priority", event)}
                        />
                    }
                    {activeFilter == "ConcernedPerson" &&
                        <div className='custom-dropdown flex flex-col flex-1'>
                            <p className="text-blue-600 font-bold text-xl px-2 py-4">Concerned Office Person</p>
                            <C3ConcernedPerson
                                onPersonSelection={onPersonSelection}
                            />
                        </div>

                    }
                </div>
                <div className={"flex self-end"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Button
                            color={"warning"}
                            variant={"contained"}
                            onClick={() => setBeneficiarySelectedFilters(defaultFilters)}
                        >
                            Reset
                        </Button>
                    <FormButtons
                        onSave={onFiltersSaveHandler}
                        onCancel={closeSideOverLay}
                    />
                    </Stack>
                </div>
            </div>
        </div>
    );
})

    