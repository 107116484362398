import React from "react"
import {observer} from "mobx-react-lite";
import {Controller, useFormContext} from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import {safe_get} from "../../../report-visuals";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";


export const RequestAssigned = observer((props:any) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    return (
        <>
            <div className="w-full mb-4">
                <Controller
                    name={props.name}
                    control={control}
                    render={({field: {onChange, onBlur, value, ref}}) => (
                        <Autocomplete
                            id="select-user"
                            freeSolo
                            size={"small"}
                            options={props.options}
                            autoHighlight={true}
                            filterOptions={(options, {inputValue}) =>
                                options.filter(
                                    (item) =>
                                        safe_get(item, "name", "").includes(inputValue) ||
                                        safe_get(item, "phonenum", "").includes(
                                            inputValue
                                        )
                                )
                            }
                            getOptionLabel={(option) =>
                                safe_get(option, "name", "")
                            }
                            onChange={(event, v) => {
                                onChange(v); // Set the value in React Hook Form
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    key={option["id"]}
                                    component="li"
                                    sx={{"& > img": {mr: 2, flexShrink: 0}}}
                                    {...props}
                                >
                                    <div>
                                        <p className={"text-purple-600"}>
                                            {option["name"]}
                                        </p>
                                        <p>{option["phone"]}</p>
                                        <p>{option["designation"]}</p>
                                    </div>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Assign To"
                                    ref={ref}
                                    onBlur={onBlur} // Call onBlur from the field
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                    sx={{
                                        zIndex: 0,
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>
    )
})